import React from 'react';
import ButtonActive from '../images/offerInfoImages/OfferInfoImageButtonActive.png';
import ButtonInactive from '../images/offerInfoImages/OfferInfoImageButtonInactive.png';
import { OfferInfoImageButtonProps, ImageCropped } from '../types/types';
import { createStyles, makeStyles, Theme, ButtonBase } from '@material-ui/core';

const ownStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      cursor: 'pointer',
      height: '42px',
      lineHeight: '42px',
      width: '64px',
      textAlign: 'center',
      verticalAlign: 'middle'
    },
    buttonTextStyleBase: {
      fontFamily: 'Lexend Tera',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '16px',
    },
  })
);

function OfferInfoImageButton(props: OfferInfoImageButtonProps) {
  const classes = ownStyles();
  return (
    <ButtonBase
      disableRipple={true}
      onClick={props.onclick}
      className={classes.container}
      style={{
        backgroundImage: props.active
          ? 'url(' + ButtonActive + ')'
          : 'url(' + ButtonInactive + ')',
        width: props.cropped === ImageCropped.None ? '64px' : '42px',
        backgroundPosition:
          props.cropped === ImageCropped.None
            ? 'center'
            : props.cropped === ImageCropped.CroppedLeft
            ? 'right center'
            : 'left center'
      }}
    >
      <span
        className={classes.buttonTextStyleBase}
        style={{ color: props.active ? '#FFFFFF' : '#342336' }}
      >
        {props.buttonId + 1}
      </span>
    </ButtonBase>
  );
}

export default OfferInfoImageButton;
