import React from 'react';
import OfferInfoHeader from '../containers/OfferInfoHeader';
import OfferInfoFooter from '../containers/OfferInfoFooter';
import { Grid } from '@material-ui/core';
import OfferInfoBody from '../containers/OfferInfoBody';
import { OfferInfoProps, OfferInfoType } from '../types/types';

function OfferInfo(props: OfferInfoProps) {
  function renderOfferInfo(param: OfferInfoType) {
    return (
      <Grid item container direction="column">
        <OfferInfoHeader offerInfoType={props.offerInfoType} />
        <OfferInfoBody offerInfoType={props.offerInfoType} />
        <OfferInfoFooter offerInfoType={props.offerInfoType} />
      </Grid>
    );
  }

  return renderOfferInfo(props.offerInfoType);
}

export default OfferInfo;
