import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import WebAppAndMobileAppCard from '../components/WebAppAndMobileAppCard';
import WebAppImg512 from '../images/webapp512x.png';
import MobileAppImg512 from '../images/mobileapp512x.png';
import WebAppAndMobileAppSectionBackground from '../images/webapp_and_mobile_app_section_design.png';

const ownStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundImg: {
      backgroundImage: `url(${WebAppAndMobileAppSectionBackground})`,
      backgroundRepeat: 'space',
      backgroundPosition: 'top',
      backgroundSize: '1272px 524px',
      alignContent: 'center',
      [theme.breakpoints.only('md')]: {
        backgroundSize: '960px 395px',
      },
      [theme.breakpoints.down('sm')]: {
        backgroundImage: 'none',
      },
    },
  })
);

function WebAppAndMobileAppSection() {
  const classes = ownStyles();

  return (
    <Grid container justify="center" className={classes.backgroundImg}>
      <WebAppAndMobileAppCard index={0} key="0" img={WebAppImg512} />
      <WebAppAndMobileAppCard index={1} key="1" img={MobileAppImg512} />
    </Grid>
  );
}

export default WebAppAndMobileAppSection;
