import React, { useLayoutEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import SiteFooterImg from '../images/site_footer.png';
import SiteFooterDesignSm from '../images/site_footer_design_sm.png';
import SiteFooterDesign from '../images/site_footer_design.png';
import SiteFooterDesignFull from '../images/site_footer_design_large.png';
import FacebookAndYoutube from '../components/FacebookAndYoutube';
import CopyrightTermsPrivacy from '../components/CopyrightTermsPrivacy';
import ArbiraLogo from '../components/ArbiraLogo';
import { Element } from 'react-scroll';

import { useTranslation } from 'react-i18next';

const ownStyles = makeStyles((theme: Theme) =>
  createStyles({
    siteFooter: {
      width: '100%',
      minHeight: '261px',
      background: '#292336',
      marginTop: '130px',
      overflow: 'hidden',
      [theme.breakpoints.only('sm')]: {
        minHeight: '293px'
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '45px',
        minHeight: '471px'
      }
    },
    contact: {
      width: '341px',
      minHeight: '125px',
      backgroundImage: `url(${SiteFooterImg})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      alignContent: 'center',
      zIndex: 1,
      [theme.breakpoints.up('md')]: {},
      [theme.breakpoints.only('md')]: {},
      [theme.breakpoints.down('xs')]: {
        width: '274px',
        minHeight: '125px'
      }
    },
    contactVia: {
      fontFamily: 'Lexend Tera',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '17px',
      textAlign: 'center',
      color: '#FFFFFF',
      marginBottom: '10px',
      textTransform: 'uppercase',
      letterSpacing: '-0.05em',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
        lineHeight: '12px',
        marginBottom: '3px'
      }
    },
    contactEmail: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '120%',
      textAlign: 'center',
      letterSpacing: '0.03em',
      color: '#E73636',
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
        lineHeight: '120%'
      }
    },
    bottomFooter: {
      maxWidth: '1022.5px',
      height: '32.73px',
      marginTop: '27px',
      zIndex: +1,
    },
    backgroundDiv: {
      position: 'absolute',
      width: 'min(100%, 688px)',
      height: '261px',
      opacity: 0.8,
      overflow: 'hidden',
      alignContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.only('sm')]: {
        height: '293px'
      },
      [theme.breakpoints.down('xs')]: {
        height: '471px'
      }
    },
    backgroundImgDiv: {
      height: '100%',
      width: '100%',
      backgroundImage: `url(${SiteFooterDesign})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',

      [theme.breakpoints.only('sm')]: {
        backgroundImage: `url(${SiteFooterDesignSm})`
      },
      [theme.breakpoints.down('xs')]: {
        backgroundImage: `url(${SiteFooterDesignFull})`
      }
    }
  })
);

// used to refresh the width of subscribe buttons
function useWindowWidth() {
  const [width, setWidth] = useState([0]);
  useLayoutEffect(() => {
    function updateWidth() {
      setWidth([window.innerWidth]);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return width;
}

function SiteFooter() {
  const classes = ownStyles();
  const { t } = useTranslation();
  const [width] = useWindowWidth();

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      className={classes.siteFooter}
    >
      <Element name="contact" className="element"></Element>
      {/* Design image */}
      <div className={classes.backgroundDiv}>
        <div className={classes.backgroundImgDiv}></div>
      </div>
      {/* Contact */}
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.contact}
      >
        {/* Contact via */}
        <Box className={classes.contactVia}>{t('siteFooter.contactVia')}</Box>
        {/* Email address */}
        <Box className={classes.contactEmail}>
          {t('siteFooter.contactEmail')}
        </Box>
      </Grid>
      {/* Bottom footer */}
      {width >= 960 && (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.bottomFooter}
        >
          {/* Copyright, Terms and Conditions, Privacy */}
          <CopyrightTermsPrivacy />
          {/* Arbira logo */}
          <ArbiraLogo />
          {/* Facebook and Youtube buttons */}
          <FacebookAndYoutube />
        </Grid>
      )}
      {width < 960 && (
        <Grid container direction="column" justify="center" alignItems="center">
          {/* Arbira logo */}
          <ArbiraLogo />
          {/* Copyright, Terms and Conditions, Privacy */}

          {/* {width >= 600 && <CopyrightTermsPrivacy />}
          {width < 600 && <CopyrightTermsPrivacy />} */}

          <CopyrightTermsPrivacy />
          {/* Facebook and Youtube buttons */}
          <FacebookAndYoutube />
        </Grid>
      )}
    </Grid>
  );
}

export default SiteFooter;
