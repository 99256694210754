import React, { useLayoutEffect, useState } from 'react';
import { Box, ButtonBase } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import LeftCorner from '../images/left_corner.png';
import RightCorner from '../images/right_corner.png';
import facebookButton from '../images/facebook.png';
import youtubeButton from '../images/youtube.png';
// import HeroVideo720 from "../videos/video_720.mp4";
import HeroVideo1080 from '../videos/video_1080.mp4';
import { useTranslation } from 'react-i18next';
import {
  RedAndDarkBlueButton,
  WhiteAndTransparentButton
} from '../components/Buttons';
import YoutubeModal from '../components/YoutubePlayer';
import { scroller } from 'react-scroll';
import { HeroProps } from '../types/types';

const ownStyles = makeStyles((theme: Theme) =>
  createStyles({
    hero: {
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        height: 'max(48vw,692px)',
        backgroundColor: '#000000',
        overflow: 'hidden'
      },
      [theme.breakpoints.only('md')]: {
        position: 'relative',
        height: 'max(46vw,680px)',
        backgroundColor: '#000000',
        alignContent: 'center',
        overflow: 'hidden'
      },
      [theme.breakpoints.only('sm')]: {
        position: 'relative',
        width: '100%',
        height: 'max(48vw,568px)',
        backgroundColor: '#000000',
        alignContent: 'center',
        overflow: 'hidden'
      },
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        height: 'max(48vw,568px)',
        backgroundColor: '#000000',
        alignContent: 'center',
        overflow: 'hidden'
      }
    },
    video: {
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        minWidth: '100%',
        opacity: '0.4',
        left: '0%'
      },
      [theme.breakpoints.only('md')]: {
        position: 'absolute',
        minHeight: '100%',
        opacity: '0.4',
        left: '0%'
      },
      [theme.breakpoints.only('sm')]: {
        position: 'absolute',
        height: '100%',
        opacity: '0.4',
        left: '0%'
      },
      [theme.breakpoints.down('xs')]: {
        minHeight: '100%',
        position: 'absolute',
        opacity: '0.4',
        left: '-70%'
      }
    },
    leftCorner: {
      position: 'absolute',
      left: '0px',
      top: 'max(calc(48vw - 199px),493px)',
      [theme.breakpoints.down('sm')]: {
        height: '40px',
        width: '74px',
        top: '530px'
      }
    },
    rightCorner: {
      position: 'absolute',
      right: '0px',
      top: 'max(calc(48vw - 199px),493px)',
      [theme.breakpoints.down('sm')]: {
        height: '40px',
        width: '74px',
        top: '530px'
      }
    },
    heroContent: {
      position: 'relative',
      zIndex: 10,
      [theme.breakpoints.up('md')]: {
        height: '560px',
        maxWidth: '840px',
        margin: '9vw max(23px, calc(50vw - 420px))'
      },
      [theme.breakpoints.only('md')]: {
        height: '548px',
        maxWidth: '840px',
        margin: '9vw max(23px, calc(50vw - 420px))'
      },
      [theme.breakpoints.only('sm')]: {
        maxHeight: '548px',
        maxWidth: 'max(500px, calc(95%-46px))',
        margin: '11.5vw 20px'
      },
      [theme.breakpoints.down('xs')]: {
        maxHeight: '548px',
        maxWidth: 'max(274px, calc(90%-46px))',
        margin: '17vw 20px 18vw 15px'
      }
    },
    title: {
      [theme.breakpoints.up('md')]: {
        fontFamily: 'Prompt',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '54px',
        lineHeight: '120%',

        display: 'flex',
        alignItems: 'flex-end',
        textAlign: 'center',
        letterSpacing: '0.03em',
        color: '#FFFFFF',
        paddingBottom: 26
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: '400px',
        fontFamily: 'Prompt',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '36px',
        lineHeight: '120%',

        display: 'flex',
        alignItems: 'flex-end',
        textAlign: 'center',
        letterSpacing: '0.03em',
        color: '#FFFFFF',
        paddingBottom: 26
      },
      [theme.breakpoints.down('xs')]: {
        fontFamily: 'Prompt',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '26px',
        lineHeight: '120%',

        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '0.03em',
        color: '#FFFFFF',
        paddingBottom: 12
      }
    },
    description: {
      [theme.breakpoints.up('md')]: {
        fontFamily: 'Prompt',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#FFFFFF',
        paddingBottom: 26
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: '570px',
        fontFamily: 'Prompt',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '21px',
        textAlign: 'center',
        color: '#FFFFFF',
        paddingBottom: 20
      },
      [theme.breakpoints.down('xs')]: {
        fontFamily: 'Prompt',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '21px',
        textAlign: 'center',
        color: '#FFFFFF',
        paddingBottom: 20
      }
    },
    buttonPadding: {
      paddingTop: '4px',
      paddingBottom: '4px',
      paddingLeft: '4px',
      paddingRight: '4px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '5px',
        paddingBottom: '5px'
      }
    },
    iconPadding: {
      [theme.breakpoints.up('md')]: {
        paddingTop: '40px'
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: '25px'
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: '15px'
      }
    },
    facebookButton: {
      [theme.breakpoints.up('sm')]: {
        width: '48px',
        height: '48px',
        paddingRight: 24,
        paddingTop: 10
      },
      [theme.breakpoints.down('sm')]: {
        width: '48px',
        height: '48px',
        paddingRight: 14,
        paddingTop: 10
      }
    },
    facebookText: {
      [theme.breakpoints.up('sm')]: {
        width: '80px',
        height: '14px',
        fontFamily: 'Lexend Tera',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '11px',
        lineHeight: '14px',
        paddingRight: 13,
        paddingTop: 10,
        textTransform: 'uppercase',
        alignItems: 'center',
        textAlign: 'center',
        color: '#FFFFFF'
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    youtubeButton: {
      [theme.breakpoints.up('sm')]: {
        width: '48px',
        height: '48px',
        paddingLeft: 24,
        paddingTop: 10
      },
      [theme.breakpoints.down('sm')]: {
        width: '48px',
        height: '48px',
        paddingLeft: 14,
        paddingTop: 10
      }
    },
    youtubeText: {
      [theme.breakpoints.up('sm')]: {
        width: '80px',
        height: '14px',
        fontFamily: 'Lexend Tera',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '11px',
        lineHeight: '14px',
        paddingLeft: 13,
        paddingTop: 10,
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: '#FFFFFF'
      },
      [theme.breakpoints.down('sm')]: { display: 'none' }
    }
  })
);

// used to refresh the width of subscribe buttons
function useWindowWidth() {
  const [width, setWidth] = useState([0]);
  useLayoutEffect(() => {
    function updateWidth() {
      setWidth([window.innerWidth]);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return width;
}

function subscribeToNewsClick() {
  scroller.scrollTo('subscribe', {
    spy: true,
    smooth: true,
    offset: -10,
    duration: 800
  });
}

function Hero(props: HeroProps) {
  const classes = ownStyles();
  const { t } = useTranslation();
  const [width] = useWindowWidth();

  let externalLinks = {
    facebook: 'https://www.facebook.com/arbirarobotics',
    youtube: 'https://www.youtube.com/channel/UCw5dcz7OEr103uNpZGgvfUg'
  };

  const sheduleDemoClick = () => {
    props.setIsDemo();
    scroller.scrollTo('subscribe', {
      spy: true,
      smooth: true,
      offset: -10,
      duration: 800
    });
  };

  const ProjectManagementButton = () => (
    <WhiteAndTransparentButton
      textValue={t('header.menu.projectManagement').toUpperCase()}
      width={width > 580 ? '407px' : '274px'}
      onclick={() => window.open('https://pms.arbira.com/', '_blank')}
    />
  );

  return (
    <Grid className={classes.hero}>
      {/* video */}
      {
        <video className={classes.video} autoPlay loop muted>
          <source src={HeroVideo1080} type="video/mp4" />
        </video>
      }
      {/* Left and Right corners */}
      <img className={classes.leftCorner} src={LeftCorner} alt={'LeftCorner'} />
      <img
        className={classes.rightCorner}
        src={RightCorner}
        alt={'RightCorner'}
      />
      {/* Content */}
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.heroContent}
      >
        {/* Title */}
        <Box className={classes.title} style={{ whiteSpace: 'pre-line' }}>
          {t('hero.title')}
        </Box>
        {/* Description */}
        <Box className={classes.description}>{t('hero.description')}</Box>
        {/* Watch video */}
        <Grid container direction="row" justify="center" alignItems="center">
          <YoutubeModal />
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.iconPadding}
        >
          <Box className={classes.buttonPadding}>
            <RedAndDarkBlueButton
              textValue={t('hero.shedule')}
              width={width > 580 ? '200px' : '274px'}
              onclick={sheduleDemoClick}
            ></RedAndDarkBlueButton>
          </Box>
          <Box className={classes.buttonPadding}>
            <RedAndDarkBlueButton
              textValue={t('hero.subscribe')}
              width={width > 580 ? '200px' : '274px'}
              onclick={subscribeToNewsClick}
            ></RedAndDarkBlueButton>
          </Box>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Box className={classes.buttonPadding}>
            <ProjectManagementButton />
          </Box>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.iconPadding}
        >
          <ButtonBase
            onClick={() => {
              window.open(externalLinks.facebook, '_blank');
            }}
          >
            <img
              className={classes.facebookButton}
              src={facebookButton}
              alt={'facebookButton'}
            />
          </ButtonBase>
          <ButtonBase
            onClick={() => {
              window.open(externalLinks.youtube, '_blank');
            }}
          >
            <img
              className={classes.youtubeButton}
              src={youtubeButton}
              alt={'youtubeButton'}
            />
          </ButtonBase>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Box className={classes.facebookText}>{t('hero.facebook')}</Box>
          <Box className={classes.youtubeText}>{t('hero.youtube')}</Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Hero;
