import React from 'react';
import { Grid } from '@material-ui/core';
//import useCommonStyles from "../../styles/Styles";
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import OfferCard from '../components/OfferCard';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';

const ownStyles = makeStyles((theme: Theme) =>
  createStyles({
    offerForSection: {
      [theme.breakpoints.up('md')]: {
        marginTop: '80px',
        padding: '0px 10px'
      },
      [theme.breakpoints.down('md')]: {
        marginTop: '80px',
        padding: '0px 10px'
      }
    }
  })
);

function OfferForSection() {
  const classes = ownStyles();
  const { t } = useTranslation();
  const numberOfOffers: number = t('offerForSection', { returnObjects: true })
    .length;
  let indexes = new Array<number>();
  for (let i = 0; i < numberOfOffers; i++) {
    indexes.push(i);
  }
  return (
    <Grid
      container
      // direction="row"
      justify="center"
      className={classes.offerForSection}
    >
      <Element name="featuresFor" className="element"></Element>
      {indexes.map(item => (
        <OfferCard index={item} key={item}></OfferCard>
      ))}
    </Grid>
  );
}

export default OfferForSection;
