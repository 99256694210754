import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import logo_abb from '../images/logos/logo_abb.png';
import logo_fanuc from '../images/logos/logo_fanuc.png';
import logo_kuka from '../images/logos/logo_kuka.png';
import logo_yaskawa from '../images/logos/logo_yaskawa.png';
import logo_cloos from '../images/logos/logo_cloos.png';
import logo_kawasaki from '../images/logos/logo_kawasaki.png';
import logo_otc from '../images/logos/logo_otc.png';
import logo_panasonic from '../images/logos/logo_panasonic.png';
import logo_staubli from '../images/logos/logo_staubli.png';
import logo_universal_robots from '../images/logos/logo_universal_robots.png';

const images = [
  { id: 1, src: logo_abb, title: 'logo_abb' },
  { id: 2, src: logo_fanuc, title: 'logo_fanuc' },
  { id: 3, src: logo_kuka, title: 'logo_kuka' },
  { id: 4, src: logo_yaskawa, title: 'logo_yaskawa' },
  { id: 5, src: logo_cloos, title: 'logo_cloos' },
  { id: 6, src: logo_kawasaki, title: 'logo_kawasaki' },
  { id: 7, src: logo_otc, title: 'logo_otc' },
  { id: 8, src: logo_panasonic, title: 'logo_panasonic' },
  { id: 9, src: logo_staubli, title: 'logo_staubli' },
  { id: 10, src: logo_universal_robots, title: 'logo_universal_robots' }
];

const ownStyles = makeStyles((theme: Theme) =>
  createStyles({
    logos: {
      [theme.breakpoints.up('md')]: {
        marginTop: '72px',
        padding: '0px 0px',
        maxWidth: '1020px'
      },
      [theme.breakpoints.down('md')]: {
        marginTop: '85px',
        padding: '0px 0px',
        maxWidth: '874px'
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '0px',
        padding: '25px 23px'
      }
    },
    bigImg: {
      [theme.breakpoints.up('md')]: {
        width: '224px',
        height: '112px',
        padding: '0px 15px'
      },
      [theme.breakpoints.down('md')]: {
        width: '196px',
        height: '98px',
        padding: '0px 10px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '134px',
        height: '67px',
        padding: '0px 0px'
      }
    },
    smallImg: {
      [theme.breakpoints.up('md')]: {
        width: '148px',
        height: '74px',
        padding: '0px 10px'
      },
      [theme.breakpoints.down('md')]: {
        width: '140px',
        height: '70px',
        padding: '0px 0px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '134px',
        height: '67px',
        padding: '0px 0px'
      }
    }
  })
);

function Logos() {
  const classes = ownStyles();
  return (
    <Grid container justify="center" className={classes.logos}>
      {images.map(({ id, src, title }) => (
        <img
          key={id}
          src={src}
          className={id < 5 ? classes.bigImg : classes.smallImg}
          alt={title}
        />
      ))}
    </Grid>
  );
}

export default Logos;
