import { Box } from '@material-ui/core';
import React from 'react';
import union from '../images/union.png';

function PlusSign() {
  return (
    <Box>
      <img src={union} alt={'union icon'} />
    </Box>
  );
}

export default PlusSign;
