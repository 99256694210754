import { Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import logo_simple from '../images/logo_simple.png';

const ownStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      // margin: "0px 410px 0px 199px",
      // [theme.breakpoints.only("md")]: {
      //   margin: "0px 337px 0px 126px",
      // },
      [theme.breakpoints.only('sm')]: {
        margin: '36px 0px 0px 0px'
      },
      [theme.breakpoints.down('xs')]: {
        margin: '36px 15px 47px 0px'
      }
    }
  })
);

function ArbiraLogo() {
  const classes = ownStyles();
  return (
    <Grid
      container
      item
      md={2}
      sm={12}
      direction="row"
      justify="center"
      alignItems="center"
    >
      <img className={classes.logo} src={logo_simple} alt={'logo_simple'} />
    </Grid>
  );
}

export default ArbiraLogo;
