import React, { useLayoutEffect, useState } from 'react';
import {
  Grid,
  Button,
  ButtonBase,
  Divider,
  useScrollTrigger
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import logo from '../images/logo.png';
import toggle from '../images/toggle.png';
import close from '../images/close.png';
import { useTranslation } from 'react-i18next';
import { scroller } from 'react-scroll';
import i18n from '../i18n';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { useHistory } from 'react-router-dom';
import { RedAndDarkBlueButton } from '../components/Buttons';

const ownStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      position: 'absolute',
      zIndex: 10,
      width: '100%',
      padding: '0px 50px',
      background:
        'linear-gradient(180deg, #292336 0%, rgba(41, 35, 54, 0) 100%)',
      [theme.breakpoints.only('md')]: {
        height: '72px',
        padding: '0px 40px'
      },
      [theme.breakpoints.only('sm')]: {
        height: '72px',
        padding: '0px 20px'
      },
      [theme.breakpoints.down('xs')]: {
        height: '40px',
        padding: '0px 20px'
      }
    },
    headerContent: {
      height: '70px',
      [theme.breakpoints.down('xs')]: {
        height: '50px'
      }
    },
    line: {
      width: '100%',
      height: '2px',
      background: '#FFFFFF'
    },
    logo: {
      [theme.breakpoints.down('xs')]: {
        height: '30px'
      }
    },
    notSelectedLanguage: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#FFFFFF'
    },
    selectedLanguage: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#E73636',
      background:
        'linear-gradient(180deg, rgba(231, 54, 54, 0) 0%, rgba(231, 54, 54, 0.16) 47.67%, rgba(231, 54, 54, 0.44) 69.62%, #E73636 100%)'
    },
    menuItem: {
      margin: '0px 10px',
      fontFamily: 'Lexend Tera',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#FFFFFF',
      letterSpacing: '-0.05em',
      textTransform: 'uppercase'
    },
    list: {
      height: '100vh',
      background: '#292336',
      padding: '14px 23px'
    },
    close: {
      fontFamily: 'Lexend Tera',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '9px',
      lineHeight: '9px',
      alignItems: 'center',
      textAlign: 'right',
      letterSpacing: '-0.1em',
      color: '#FFFFFF'
    }
  })
);

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function ScrollTrigger(props: Props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 500,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    style: {
      background: trigger
        ? '#292336'
        : 'linear-gradient(180deg, #292336 0%, rgba(41, 35, 54, 0) 100%)',
      transition: trigger ? '0.5s ease-in' : '0.5s ease-out'
    }
  });
}

function scrollToFeaturesFor() {
  scroller.scrollTo('featuresFor', {
    spy: true,
    smooth: true,
    offset: -10,
    duration: 800
  });
}

function scrollToSubscribe() {
  scroller.scrollTo('subscribe', {
    spy: true,
    smooth: true,
    offset: -10,
    duration: 800
  });
}

function scrollToContact() {
  scroller.scrollTo('contact', {
    spy: true,
    smooth: true,
    offset: -10,
    duration: 800
  });
}

function Header() {
  const [popupOpen, changePopupState] = useState(false);

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    changePopupState(open);
  };

  const ToggleMenu = () => (
    <div
      className={classes.list}
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Grid
        container
        item
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <img src={logo} alt={'logo'} className={classes.logo} />
        </Grid>
        <Grid item>
          <Button className={classes.close}>{t('header.menu.close')}</Button>
          <img src={close} alt={'close'} />
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="column"
        justify="space-between"
        alignItems="center"
        spacing={2}
        style={{ padding: '20px', marginTop: '100px' }}
      >
        <Grid item>
          <EnButton />
        </Grid>
        <Grid item>
          <DeButton />
        </Grid>
        <Grid item>
          <JpButton />
        </Grid>
        <Divider
          style={{
            background: '#FFFFFF',
            width: '100%',
            height: '1px',
            margin: '50px 0px'
          }}
        />
        <Grid item>
          <FeaturesForButton />
        </Grid>
        <Grid item>
          <SubscribeButton />
        </Grid>
        <Grid item>
          <ContactButton />
        </Grid>
        <Grid item>
          <ProjectManagementButton />
        </Grid>
      </Grid>
    </div>
  );

  let history = useHistory();

  function hangdleEN() {
    if (i18n.languages[0] !== 'en') i18n.changeLanguage('en');
    history.push('/');
  }

  function hangdleDE() {
    if (i18n.languages[0] !== 'de') i18n.changeLanguage('de');
    history.push('/de');
  }

  function hangdleJP() {
    if (i18n.languages[0] !== 'jp') i18n.changeLanguage('jp');
    history.push('/jp');
  }

  const EnButton = () => (
    <Button
      onClick={hangdleEN}
      className={
        i18n.languages[0] === 'en'
          ? classes.selectedLanguage
          : classes.notSelectedLanguage
      }
    >
      {t('header.menu.en')}
    </Button>
  );

  const DeButton = () => (
    <Button
      onClick={hangdleDE}
      className={
        i18n.languages[0] === 'de'
          ? classes.selectedLanguage
          : classes.notSelectedLanguage
      }
    >
      {t('header.menu.de')}
    </Button>
  );

  const JpButton = () => (
    <Button
      onClick={hangdleJP}
      className={
        i18n.languages[0] === 'jp'
          ? classes.selectedLanguage
          : classes.notSelectedLanguage
      }
    >
      {t('header.menu.jp')}
    </Button>
  );

  const FeaturesForButton = () => (
    <Button className={classes.menuItem} onClick={scrollToFeaturesFor}>
      {t('header.menu.featuresFor')}
    </Button>
  );

  const SubscribeButton = () => (
    <Button className={classes.menuItem} onClick={scrollToSubscribe}>
      {t('header.menu.subscribe')}
    </Button>
  );

  const ProjectManagementButton = () => (
    <RedAndDarkBlueButton
      textValue={t('header.menu.projectManagement').toUpperCase()}
      width="250px"
      onclick={() => window.open('https://pms.arbira.com/', '_blank')}
    />
  );

  const ContactButton = () => (
    <Button className={classes.menuItem} onClick={scrollToContact}>
      {t('header.menu.contact')}
    </Button>
  );

  const classes = ownStyles();
  const [width] = useWindowWidth();
  const { t } = useTranslation();
  return (
    <ScrollTrigger>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={classes.header}
      >
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.headerContent}
          >
            <Grid
              container
              item
              md={5}
              sm={11}
              xs={11}
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={2}
            >
              {/* logo and Arbira */}
              <Grid item>
                <img src={logo} alt={'logo'} className={classes.logo} />
              </Grid>
              {/* Languages */}
              {width >= 1270 && (
                <Grid item>
                  <EnButton />
                </Grid>
              )}
              {width >= 1270 && (
                <Grid item>
                  <DeButton />
                </Grid>
              )}
              {width >= 1270 && (
                <Grid item>
                  <JpButton />
                </Grid>
              )}
            </Grid>
            {/* menu */}
            {width >= 1270 && (
              <Grid
                container
                item
                md={7}
                sm={1}
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                {/* menu items */}
                <Grid item>
                  <FeaturesForButton />
                </Grid>
                <Grid item>
                  <SubscribeButton />
                </Grid>
                <Grid item>
                  <ContactButton />
                </Grid>
                <Grid item>
                  <ProjectManagementButton />
                </Grid>
              </Grid>
            )}
            {/* toggle */}
            {width < 1270 && (
              <Grid container item md={7} sm={1} xs={1} justify="flex-end">
                <ButtonBase onClick={toggleDrawer(true)}>
                  <img src={toggle} alt={'logo'} />
                </ButtonBase>
                <SwipeableDrawer
                  anchor={'top'}
                  open={popupOpen}
                  onClose={toggleDrawer(false)}
                  onOpen={toggleDrawer(true)}
                >
                  {ToggleMenu()}
                </SwipeableDrawer>
              </Grid>
            )}
          </Grid>
          <div className={classes.line} />
        </Grid>
      </Grid>
    </ScrollTrigger>
  );
}

export default Header;

// used to refresh the width of subscribe buttons
function useWindowWidth() {
  const [width, setWidth] = useState([0]);
  useLayoutEffect(() => {
    function updateWidth() {
      setWidth([window.innerWidth]);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return width;
}
