import React from 'react';
import { OfferInfoCardProps } from '../types/types';
import {
  makeStyles,
  Theme,
  createStyles,
  Grid,
  ButtonBase,
  Box
} from '@material-ui/core';

const cardStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContent: {
      marginTop: '60px',
      width: '293px',
      textAlign: 'center',
      alignItems: 'normal',
      verticalAlign: 'top',
      marginBottom: '11px',
      [theme.breakpoints.down(1350)]: {
        width: '205px'
      },
      [theme.breakpoints.down(1024)]: {
        width: '274px'
      }
    },
    icon: {
      width: '100px',
      height: '100px'
    },
    titleActive: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '36px',
      letterSpacing: '0.03em',
      color: '#292336',
      marginTop: '20px',
      [theme.breakpoints.down(1024)]: {
        fontSize: '20px',
        lineHeight: '30px'
      }
    },
    descriptionActive: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '26px',
      textAlign: 'center',
      letterSpacing: '-0.02em',
      color: '#000000',
      marginTop: '15px',
      [theme.breakpoints.down(1024)]: {
        fontSize: '14px',
        lineHeight: '26px'
      }
    }
  })
);

function OfferInfoCard(props: OfferInfoCardProps) {
  const ownStyles = cardStyles();

  function createCard() {
    return props.active ? (
      <Box
        className={ownStyles.cardContent}
        style={{
          marginBottom: 10
        }}
      >
        <Grid>
          <img
            src={require(`../images/offerInfoImages/${props.card.icon}`)}
            alt="icon"
            className={ownStyles.icon}
          />
          <Grid
            item
            className={ownStyles.titleActive}
            style={props.active ? { opacity: '1' } : { opacity: '0.5' }}
          >
            {props.card.title}
          </Grid>
          <Grid
            item
            className={ownStyles.descriptionActive}
            style={props.active ? { opacity: '1' } : { opacity: '0.5' }}
          >
            {props.card.description}
          </Grid>
        </Grid>
      </Box>
    ) : (
      <ButtonBase
        className={ownStyles.cardContent}
        onClick={props.onclick}
        disableRipple={props.disableRiple}
        style={{ cursor: props.cursorHower ? 'pointer' : 'default' }}
      >
        <Grid>
          <img
            src={require(`../images/offerInfoImages/${props.card.icon}`)}
            alt="icon"
            className={ownStyles.icon}
          />
          <Grid
            item
            className={ownStyles.titleActive}
            style={props.active ? { opacity: '1' } : { opacity: '0.5' }}
          >
            {props.card.title}
          </Grid>
          <Grid
            item
            className={ownStyles.descriptionActive}
            style={props.active ? { opacity: '1' } : { opacity: '0.5' }}
          >
            {props.card.description}
          </Grid>
        </Grid>
      </ButtonBase>
    );
  }

  return createCard();
}

export default OfferInfoCard;
