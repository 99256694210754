import {
  ButtonBase,
  Box,
  Grid,
  makeStyles,
  Theme,
  createStyles
} from '@material-ui/core';
import React from 'react';
import { ButtonProps, AlignTo } from '../types/types';

const redAndDarkBlueButtonOuterRectangle = {
  border: '1px solid #E73636',
  boxSizing: 'border-box',
  borderRadius: '5px'
} as React.CSSProperties;
const redAndDarkBlueButtonInnerRectangle = {
  border: '3px solid #000000',
  boxSizing: 'border-box',
  borderRadius: '5px'
} as React.CSSProperties;
const redAndDarkBlueButtonStyle = {
  backgroundColor: '#E73636',
  width: '100%',
  height: '40px',
  fontSize: '11px',
  lineHeight: '14px',
  fontFamily: 'Lexend Tera',
  color: '#FFFFFF',
  letterSpacing: '-0.1em'
};
const redAndWhiteButtonOuterRectangle = {
  border: '1px solid #E73636',
  boxSizing: 'border-box',
  borderRadius: '5px'
} as React.CSSProperties;
const redAndWhiteButtonInnerRectangle = {
  border: '3px solid #F6F6F6',
  boxSizing: 'border-box',
  borderRadius: '5px'
} as React.CSSProperties;
const redAndWhiteButtonStyle = {
  backgroundColor: '#E73636',
  height: '40px',
  width: '100%',
  fontSize: '11px',
  lineHeight: '14px',
  fontFamily: 'Lexend Tera',
  color: '#FFFFFF',
  letterSpacing: '-0.1em'
};

const whiteAndTransparentButtonOuterRectangle = {
  border: '1px solid #ffffff',
  boxSizing: 'border-box',
  borderRadius: '5px'
} as React.CSSProperties;
const whiteAndTransparentButtonInnerRectangle = {
  border: '3px solid transparent',
  boxSizing: 'border-box',
  borderRadius: '5px'
} as React.CSSProperties;
const whiteAndTransparentButtonStyle = {
  backgroundColor: '#ffffff',
  height: '40px',
  width: '100%',
  fontSize: '11px',
  lineHeight: '14px',
  fontFamily: 'Lexend Tera',
  color: '##292336',
  letterSpacing: '-0.1em'
};

const noBackgrButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    noBckgrButtonTextInactive: {
      fontFamily: 'Lexend Tera',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '17px',
      lineHeight: '17px',
      textAlign: 'left',
      height: '40px',
      borderBottom: 'solid 3px transparent',
      color: 'rgba(18, 18, 18, 0.2)',
      letterSpacing: '-0.1em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '13px',
        lineHeight: '13px',
        height: '32px'
      },
      [theme.breakpoints.between(0, 380)]: {
        height: '45px'
      }
    },
    noBckgrButtonTextActive: {
      fontFamily: 'Lexend Tera',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '17px',
      lineHeight: '17px',
      color: '#121212',
      textAlign: 'left',
      height: '40px',
      borderBottom: 'solid 3px #E73636',
      letterSpacing: '-0.1em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '13px',
        lineHeight: '13px',
        height: '32px'
      },
      [theme.breakpoints.between(0, 380)]: {
        height: '45px'
      }
    }
  })
);

const bottomButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomButtonWidth: {
      width: '500px',
      height: '120px',
      verticalAlign: 'text-top',
      [theme.breakpoints.down('md')]: {
        width: '372px',
        height: '96px'
      },
      [theme.breakpoints.down(1024)]: {
        width: '328px',
        height: '47px'
      },
      [theme.breakpoints.down('xs')]: {
        width: '266px'
      }
    },
    bottomButtonTitleActive: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '36px',
      lineHeight: '120%',
      color: '#E73636',
      letterSpacing: '0.03em',
      [theme.breakpoints.down('md')]: {
        fontSize: '28px',
        lineHeight: '120%'
      },
      [theme.breakpoints.down(1024)]: {
        fontSize: '16px',
        lineHeight: '120%'
      }
    },
    bottomButtonTitleInactive: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '36px',
      lineHeight: '120%',
      letterSpacing: '0.03em',
      opacity: '0.2',
      color: '#292336',
      [theme.breakpoints.down('md')]: {
        fontSize: '28px',
        lineHeight: '120%'
      },
      [theme.breakpoints.down(1024)]: {
        display: 'none'
      }
    },
    bottomButtonHeaderActive: {
      fontFamily: 'Lexend Tera',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '13px',
      lineHeight: '13px',
      color: '#000000',
      marginBottom: '10px',
      letterSpacing: '-0.01em',
      textTransform: 'uppercase',
      [theme.breakpoints.down(1024)]: {
        fontSize: '11px',
        lineHeight: '11px'
      }
    },
    bottomButtonHeaderInctive: {
      fontFamily: 'Lexend Tera',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '13px',
      lineHeight: '13px',
      opacity: '0.2',
      color: '#000000',
      marginBottom: '10px',
      letterSpacing: '-0.01em',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: '11px',
        lineHeight: '11px'
      },
      [theme.breakpoints.down(1024)]: {
        display: 'none'
      }
    }
  })
);

export function RedAndDarkBlueButton(buttonProps: ButtonProps) {
  return (
    <Box style={{ width: buttonProps.width }}>
      <Box style={redAndDarkBlueButtonOuterRectangle}>
        <Box style={redAndDarkBlueButtonInnerRectangle}>
          <ButtonBase
            disableRipple={true}
            style={redAndDarkBlueButtonStyle}
            onClick={buttonProps.onclick}
            disabled={buttonProps.disabled}
          >
            {buttonProps.textValue}
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
}

export function RedAndWhiteButton(buttonProps: ButtonProps) {
  return (
    <Box style={{ width: buttonProps.width }}>
      <Box style={redAndWhiteButtonOuterRectangle}>
        <Box style={redAndWhiteButtonInnerRectangle}>
          <ButtonBase
            disableRipple={true}
            style={redAndWhiteButtonStyle}
            onClick={buttonProps.onclick}
            disabled={buttonProps.disabled}
          >
            {buttonProps.textValue}
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
}

export function WhiteAndTransparentButton(buttonProps: ButtonProps) {
  return (
    <Box style={{ width: buttonProps.width }}>
      <Box style={whiteAndTransparentButtonOuterRectangle}>
        <Box style={whiteAndTransparentButtonInnerRectangle}>
          <ButtonBase
            disableRipple={true}
            style={whiteAndTransparentButtonStyle}
            onClick={buttonProps.onclick}
            disabled={buttonProps.disabled}
          >
            {buttonProps.textValue}
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
}

export function NoBckgrButton(buttonProps: ButtonProps) {
  const classes = noBackgrButtonStyles();
  return (
    <ButtonBase
      disableRipple={true}
      className={
        buttonProps.active
          ? classes.noBckgrButtonTextActive
          : classes.noBckgrButtonTextInactive
      }
      onClick={buttonProps.onclick}
      disabled={buttonProps.disabled}
    >
      {buttonProps.textValue}
    </ButtonBase>
  );
}

export function OfferBottomButton(buttonProps: ButtonProps) {
  const classes = bottomButtonStyles();
  return (
    <Box
      className={classes.bottomButtonWidth}
      style={
        buttonProps.alignTo === AlignTo.Left ||
        buttonProps.alignTo === AlignTo.Right
          ? buttonProps.alignTo === AlignTo.Left
            ? { textAlign: 'left' }
            : { textAlign: 'right' }
          : { textAlign: 'center' }
      }
    >
      <ButtonBase
        disableRipple={true}
        onClick={buttonProps.onclick}
        disabled={buttonProps.disabled}
        style={
          buttonProps.alignTo === AlignTo.Left ||
          buttonProps.alignTo === AlignTo.Right
            ? buttonProps.alignTo === AlignTo.Left
              ? { textAlign: 'left' }
              : { textAlign: 'right' }
            : { textAlign: 'center' }
        }
      >
        <Grid>
          <Grid
            className={
              buttonProps.active
                ? classes.bottomButtonHeaderActive
                : classes.bottomButtonHeaderInctive
            }
          >
            {buttonProps.headerText}
          </Grid>
          <Grid
            className={
              buttonProps.active
                ? classes.bottomButtonTitleActive
                : classes.bottomButtonTitleInactive
            }
          >
            {buttonProps.textValue}
          </Grid>
        </Grid>
      </ButtonBase>
    </Box>
  );
}
