/* eslint-disable react/jsx-no-target-blank */
import React, { useLayoutEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import terms from '../pdfs/terms.pdf';
import privacy from '../pdfs/privacy.pdf';

const ownStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomContent: {
      [theme.breakpoints.down('sm')]: { margin: '10px 0px', zIndex: '10' }
    },
    bottomContentLinkItem: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '18px',
      textAlign: 'center',
      letterSpacing: '0.1em',
      color: '#FFFFFF',
      flex: 'none',
      alignSelf: 'center',
      margin: '15px 0px',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      },
      [theme.breakpoints.only('sm')]: {},
      [theme.breakpoints.down('xs')]: { margin: '10px 0px' }
    },
    bottomContentItem: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '18px',
      textAlign: 'center',
      letterSpacing: '0.045em',
      color: '#FFFFFF',
      flex: 'none',
      alignSelf: 'center',
      margin: '15px 0px',
      [theme.breakpoints.only('sm')]: {},
      [theme.breakpoints.down('xs')]: { margin: '10px 0px' }
    }
  })
);

// used to refresh the width of subscribe buttons
function useWindowWidth() {
  const [width, setWidth] = useState([0]);
  useLayoutEffect(() => {
    function updateWidth() {
      setWidth([window.innerWidth]);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return width;
}

function CopyrightTermsPrivacy() {
  const { t } = useTranslation();
  const classes = ownStyles();
  const [width] = useWindowWidth();
  return (
    <Grid
      container
      item
      md={5}
      sm={12}
      direction={width >= 600 ? 'row' : 'column'}
      justify={width >= 960 ? 'flex-start' : 'center'}
      alignItems="center"
      spacing={2}
      className={classes.bottomContent}
    >
      <Grid item sm="auto" xs={12}>
        <div className={classes.bottomContentItem}>
          {t('siteFooter.copyright')}
        </div>
      </Grid>
      <Grid item sm="auto" xs={12}>
        <a
          href={terms}
          target="_blank"
          className={classes.bottomContentLinkItem}
        >
          {t('siteFooter.termsAndConditions')}
        </a>
      </Grid>
      <Grid item sm="auto" xs={12}>
        <a
          href={privacy}
          target="_blank"
          className={classes.bottomContentLinkItem}
        >
          {t('siteFooter.privacy')}
        </a>
      </Grid>
    </Grid>
  );
}

export default CopyrightTermsPrivacy;
