import React, { useLayoutEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { ButtonBase, Box } from '@material-ui/core';
import playButton from '../images/play_alt.png';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

let youtubeLink: string = 'https://www.youtube.com/watch?v=z4gifz8KH-U';

class YoutubePlayer extends React.Component {
  ViedoOnReady(event: {
    target: {
      pauseVideo: () => void;
      setSize(width: Number, height: Number): Object;
    };
  }) {
    // let player = event.target;
    // player.pauseVideo();
    // console.log(player);
  }

  render() {
    return (
      <ReactPlayer
        url={youtubeLink}
        playing
        controls
        width="100%"
        height="100%"
      />
    );
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    youtubePaper1920DownTo1700: {
      position: 'absolute',
      width: '1280px',
      height: '720px',
      left: 'calc(50% - 640px)',
      top: '72px'
    },
    youtubePaper1700DownTo1550: {
      position: 'absolute',
      width: '1155.6px',
      height: '650px',
      left: 'calc(50% - 577.77px)',
      top: '72px'
    },
    youtubePaper1550DownTo1400: {
      position: 'absolute',
      width: '1084.4px',
      height: '610px',
      left: 'calc(50% - 542.2px)',
      top: '72px'
    },
    youtubePaper1400DownTo1280: {
      position: 'absolute',
      width: '888.88px',
      height: '500px',
      left: 'calc(50% - 444.44px)',
      top: '72px'
    },
    youtubePaper1280DownTo960: {
      position: 'absolute',
      width: '817.77px',
      height: '460px',
      left: 'calc(50% - 408.88px)',
      top: '72px'
    },
    youtubePaper960DownTo720: {
      position: 'absolute',
      width: '693.33px',
      height: '390px',
      left: 'calc(50% - 346.66px)',
      top: '72px'
    },
    youtubePaper720DownTo600: {
      position: 'absolute',
      width: '580px',
      height: '326.2px',
      left: 'calc(50% - 290px)',
      top: '72px'
    },
    youtubePaper600DownTo450: {
      position: 'absolute',
      width: '430px',
      height: '241.87px',
      left: 'calc(50% - 215px)',
      top: '52px'
    },
    youtubePaper450DownTo300: {
      position: 'absolute',
      width: '280px',
      height: '157.5px',
      left: 'calc(50% - 140px)',
      top: '52px'
    },
    youtubePanel: {
      // background: "#FFFFFF",
      height: '100%'
    },
    playButton: {
      width: '48px',
      height: '48px'
    },
    watchVideoText: {
      width: '120px',
      height: '24px',
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#FFFFFF',
      textAlign: 'left'
    }
  })
);

// used to refresh the width of subscribe buttons
function useWindowWidth() {
  const [width, setWidth] = useState([0]);
  useLayoutEffect(() => {
    function updateWidth() {
      setWidth([window.innerWidth]);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return width;
}

export default function YoutubeModal() {
  const [width] = useWindowWidth();
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <Box
      className={
        width > 1700
          ? classes.youtubePaper1920DownTo1700
          : width > 1550
          ? classes.youtubePaper1700DownTo1550
          : width > 1400
          ? classes.youtubePaper1550DownTo1400
          : width > 1280
          ? classes.youtubePaper1400DownTo1280
          : width > 960
          ? classes.youtubePaper1280DownTo960
          : width > 720
          ? classes.youtubePaper960DownTo720
          : width > 600
          ? classes.youtubePaper720DownTo600
          : width > 450
          ? classes.youtubePaper600DownTo450
          : classes.youtubePaper450DownTo300
      }
    >
      <YoutubePlayer />
    </Box>
  );

  return (
    <div>
      <ButtonBase type="button" onClick={handleOpen}>
        <img
          className={classes.playButton}
          src={playButton}
          alt={'playButton'}
        />
        <Box className={classes.watchVideoText}>{t('hero.video')}</Box>
      </ButtonBase>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
