import React, { useState, FormEvent } from 'react';
import { Box, Grid, Input, Modal } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import StayUpToDate from '../images/stay_up_to_date.png';
import StayUpToDateMd from '../images/stay_up_to_date_md.png';
import { useTranslation } from 'react-i18next';
import { RedAndDarkBlueButton } from '../components/Buttons';
import { Element } from 'react-scroll';
import emailjs from 'emailjs-com';
import { SubscribeProps } from '../types/types';

const ownStyles = makeStyles((theme: Theme) =>
  createStyles({
    subscribe: {
      [theme.breakpoints.up('md')]: {
        margin: '80px 0px 40px 0px',
        width: '1240px',
        height: '345px',
        backgroundImage: `url(${StayUpToDate})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      },
      [theme.breakpoints.only('md')]: {
        margin: '80px 0px 40px 0px',
        width: '100%',
        height: '345px',
        backgroundImage: `url(${StayUpToDateMd})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0px 0px',
        width: '100%',
        height: '345px',
        backgroundImage: `url(${StayUpToDateMd})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }
    },
    subscribeContent: {
      [theme.breakpoints.up('md')]: {
        marginTop: '73px'
      },
      [theme.breakpoints.only('md')]: { marginTop: '60px' },
      [theme.breakpoints.down('sm')]: { marginTop: '45px' }
    },
    title: {
      [theme.breakpoints.up('xs')]: {
        fontFamily: 'Prompt',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '36px',
        lineHeight: '120%',
        display: 'flex',
        letterSpacing: '0.03em',
        color: '#FFFFFF',
        paddingBottom: '20px'
      },
      [theme.breakpoints.down('xs')]: {
        fontFamily: 'Prompt',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '120%',
        display: 'flex',
        letterSpacing: '0.03em',
        color: '#FFFFFF',
        paddingBottom: 9
      }
    },
    description: {
      [theme.breakpoints.up('md')]: {
        maxWidth: '725px',
        fontFamily: 'Prompt',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '-0.02em',
        color: '#FFFFFF',
        paddingBottom: 36
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: '600px',
        padding: '0px 10px',
        fontFamily: 'Prompt',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '21px',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '-0.02em',
        color: '#FFFFFF',
        paddingBottom: 35
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '500px',
        padding: '0px 10px',
        fontFamily: 'Prompt',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '21px',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '-0.02em',
        color: '#FFFFFF',
        paddingBottom: 26
      }
    },
    formWrapper: {
      [theme.breakpoints.up('md')]: {},
      [theme.breakpoints.only('sm')]: {},
      [theme.breakpoints.down('xs')]: { maxWidth: '300px' }
    },
    form: {
      [theme.breakpoints.up('md')]: { padding: '0px 7px' },
      [theme.breakpoints.only('sm')]: { padding: '0px 7px' },
      [theme.breakpoints.down('xs')]: { padding: '0px 7px 10px 0px' }
    },
    input: {
      [theme.breakpoints.up('md')]: {
        width: '300px',
        height: '40px',
        background: 'rgba(255, 255, 255, 0.2)',
        border: '1px solid #FFFFFF',
        boxSizing: 'border-box',
        borderRadius: '5px',
        padding: '0px 0px',
        color: '#FFFFFF',
        paddingLeft: '19px'
      },
      [theme.breakpoints.only('sm')]: {
        width: '300px',
        height: '40px',
        background: 'rgba(255, 255, 255, 0.2)',
        border: '1px solid #FFFFFF',
        boxSizing: 'border-box',
        borderRadius: '5px',
        padding: '0px 0px',
        color: '#FFFFFF',
        paddingLeft: '19px'
      },
      [theme.breakpoints.down('xs')]: {
        width: '269px',
        height: '40px',
        background: 'rgba(255, 255, 255, 0.2)',
        border: '1px solid #FFFFFF',
        boxSizing: 'border-box',
        borderRadius: '5px',
        padding: '0px 0px',
        color: '#FFFFFF',
        paddingLeft: '19px'
      }
    },
    subscribedModal: {
      position: 'absolute',
      bottom: '15px',
      right: '15px',
      width: '200px',
      height: '170px',
      background: '#292336',
      borderRadius: '15px',
      padding: '10px',
      alignContent: 'center'
    }
  })
);

function SubscribeSection(props: SubscribeProps) {
  const classes = ownStyles();
  const { t } = useTranslation();
  const [emailAddress, setValue] = useState('');
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div className={classes.subscribedModal}>
      <p
        id="description"
        className={classes.description}
        style={{ paddingBottom: '5px' }}
      >
        {t('subscribeSection.modal')}
      </p>
      <RedAndDarkBlueButton
        textValue={t('subscribeSection.modalOK')}
        width="200px"
        onclick={handleClose}
      />
    </div>
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const API_KEY = 'user_lRWCVkTonUi5hxYqbX93n';
  const SERVIDE_ID = 'ARBIRA';
  const SUBSCRIBE_TEMPLATE_ID = 'subscribed';
  const DEMO_TEMPLATE_ID = 'demo';

  const subscribeClick = () => {
    let template_id: string = SUBSCRIBE_TEMPLATE_ID;
    if (props.isDemo === true) {
      template_id = DEMO_TEMPLATE_ID;
    }
    if (
      emailAddress.indexOf('@') !== -1 &&
      emailAddress.indexOf('@') !== 0 &&
      emailAddress.indexOf('@') !== emailAddress.length - 1
    ) {
      var subsriber = {
        emailAddress: emailAddress
      };

      emailjs
        .send(SERVIDE_ID, template_id, subsriber, API_KEY)
        .then(
          function(response: { status: any; text: any }) {
            console.log('SUCCESS!', response.status, response.text);
          },
          function(error: any) {
            console.log('FAILED...', error);
          }
        )
        .then(() => setValue(''))
        .then(handleOpen);
    }
  };

  return (
    <Box className={classes.subscribe}>
      <Element name="subscribe" className="element"></Element>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.subscribeContent}
      >
        <Box className={classes.title}>{t('subscribeSection.title')}</Box>
        <Box className={classes.description}>
          {t('subscribeSection.description')}
        </Box>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.formWrapper}
        >
          <form
            noValidate
            autoComplete="off"
            className={classes.form}
            onSubmit={handleSubmit}
          >
            <Input
              placeholder={t('subscribeSection.input')}
              className={classes.input}
              value={emailAddress}
              onChange={e => setValue(e.target.value)}
              type="email"
            />
          </form>
          <RedAndDarkBlueButton
            textValue={t('subscribeSection.button')}
            width="168px"
            onclick={subscribeClick}
          />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="title"
            aria-describedby="description"
          >
            {body}
          </Modal>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SubscribeSection;
