import React, { useState } from 'react';
import Hero from '../containers/Hero';
import OfferForSection from '../containers/OfferForSection';
import { AppBar, Grid } from '@material-ui/core';
import Logos from '../containers/Logos';
import SubscribeSection from '../containers/SubscribeSection';
import WebAppAndMobileAppSection from '../containers/WebAppAndMobileAppSection';
import SiteFooter from '../containers/SiteFooter';
import Header from '../containers/Header';

function LandingPage() {
  let [isDemo, setIsDemo] = useState(false);

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <AppBar position="sticky">
        <Header />
      </AppBar>
      <Hero setIsDemo={() => setIsDemo(true)} />
      <OfferForSection />
      <Logos />
      <SubscribeSection isDemo={isDemo} />
      <WebAppAndMobileAppSection />
      <SiteFooter />
      {/* <Tester /> */}
    </Grid>
  );
}

export default LandingPage;
