import { Grid, ButtonBase } from '@material-ui/core';
import React, { useLayoutEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import facebookButton from '../images/facebook.png';
import youtubeButton from '../images/youtube.png';

const ownStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomButtons: {},
    sizes: {
      width: '29.45px',
      height: '29.45px',
      [theme.breakpoints.only('sm')]: {
        marginTop: '5px',
        marginBottom: '30px',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '50px',
        marginBottom: '30px'
      }
    }
  })
);

// used to refresh the width of subscribe buttons
function useWindowWidth() {
  const [width, setWidth] = useState([0]);
  useLayoutEffect(() => {
    function updateWidth() {
      setWidth([window.innerWidth]);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return width;
}

function FacebookAndYoutube() {
  const classes = ownStyles();
  const [width] = useWindowWidth();
  let externalLinks = {
    facebook: 'https://www.facebook.com/arbirarobotics',
    youtube: 'https://www.youtube.com/channel/UC76alOKc5TOHIBGcY6zGpcg'
  };
  return (
    <Grid
      container
      item
      md={5}
      sm={12}
      direction="row"
      justify={width >= 960 ? 'flex-end' : 'center'}
      alignItems="center"
      spacing={2}
      className={classes.bottomButtons}
    >
      <Grid item>
        <ButtonBase
          onClick={() => {
            window.open(externalLinks.youtube, '_blank');
          }}
        >
          <img
            className={classes.sizes}
            src={facebookButton}
            alt={'facebookButton'}
          />
        </ButtonBase>
      </Grid>
      <Grid item>
        <ButtonBase
          onClick={() => {
            window.open(externalLinks.youtube, '_blank');
          }}
        >
          <img
            className={classes.sizes}
            src={youtubeButton}
            alt={'youtubeButton'}
          />
        </ButtonBase>
      </Grid>
    </Grid>
  );
}

export default FacebookAndYoutube;
