import React from 'react';
import LandingPage from './pages/LandingPage';
import OfferInfo from './pages/OfferInfo';
import { OfferInfoType } from './types/types';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';

function App() {
  const { t } = useTranslation();

  return (
    <BrowserRouter>
      {modifyLanguageIfNeeded()}
      <ScrollToTop>
        <Helmet>
          <title>{t('pageInfo.title')}</title>
          <meta name="description" content={t('pageInfo.description')} />
          <meta name="keywords" content={t('pageInfo.keywords')} />
          <meta name="author" content={t('pageInfo.author')} />
        </Helmet>
        <Switch>
          <Route exact path="/" component={() => <LandingPage />} />
          <Route exact path="/de" component={() => <LandingPage />} />
          <Route exact path="/jp" component={() => <LandingPage />} />
          <Route
            exact
            path="/system-integrators"
            component={() => (
              <OfferInfo offerInfoType={OfferInfoType.SystemIntegrator} />
            )}
          />
          <Route
            exact
            path="/de/system-integrators"
            component={() => (
              <OfferInfo offerInfoType={OfferInfoType.SystemIntegrator} />
            )}
          />
          <Route
            exact
            path="/jp/system-integrators"
            component={() => (
              <OfferInfo offerInfoType={OfferInfoType.SystemIntegrator} />
            )}
          />
          <Route
            exact
            path="/offline-robot-programming-vendors"
            component={() => <OfferInfo offerInfoType={OfferInfoType.Olrp} />}
          />
          <Route
            exact
            path="/de/offline-robot-programming-vendors"
            component={() => <OfferInfo offerInfoType={OfferInfoType.Olrp} />}
          />
          <Route
            exact
            path="/jp/offline-robot-programming-vendors"
            component={() => <OfferInfo offerInfoType={OfferInfoType.Olrp} />}
          />
          <Redirect to="/" />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export function modifyLanguageIfNeeded() {
  let langFromPath: string = '';
  switch (window.location.pathname) {
    case '/':
    case '/system-integrators':
    case '/offline-robot-programming-vendors':
      langFromPath = 'en';
      break;
    case '/de':
    case '/de/system-integrators':
    case '/de/offline-robot-programming-vendors':
      langFromPath = 'de';
      break;
    case '/jp':
    case '/jp/system-integrators':
    case '/jp/offline-robot-programming-vendors':
      langFromPath = 'jp';
      break;
    default:
      langFromPath = 'en';
      break;
  }
  if (i18n.languages[0] !== langFromPath) i18n.changeLanguage(langFromPath);
}

export default App;
