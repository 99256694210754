import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoBckgrButton } from '../components/Buttons';
import { makeStyles, createStyles, Theme, Grid, Box } from '@material-ui/core';
import { OfferInfoProps, OfferInfoType } from '../types/types';
import { useHistory } from 'react-router-dom';

const ownStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContent: {
      margin: '50px 5% 0px 5%',
      width: '90%',
      [theme.breakpoints.down(1025)]: {
        margin: '0',
        marginTop: '50px',
        width: '100%'
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0',
        marginTop: '20px',
        width: '100%'
      }
    },
    line: {
      width: '100%',
      height: '2px',
      background: 'rgba(18, 18, 18, 0.2)'
    },
    offer: {
      fontFamily: 'Lexend Tera',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '13px',
      lineHeight: '13px',
      marginTop: '40px',
      marginLeft: '100px',
      color: '#000000',
      letterSpacing: '-0.1em',
      [theme.breakpoints.down('md')]: {
        marginLeft: '0px'
      },
      [theme.breakpoints.down(1025)]: {
        marginLeft: '5%'
      },
      [theme.breakpoints.down(380)]: {
        marginLeft: '25px'
      }
    },
    title: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '36px',
      lineHeight: '120%',
      color: '#292336',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      marginTop: '10px',
      marginLeft: '100px',
      letterSpacing: '0.03em',
      [theme.breakpoints.down('md')]: {
        marginLeft: '0px'
      },
      [theme.breakpoints.down(1025)]: {
        fontSize: '26px',
        marginTop: '5px',
        marginLeft: '5%'
      },
      [theme.breakpoints.down('xs')]: {
        width: '300px'
      },
      [theme.breakpoints.down(380)]: {
        width: '300px',
        marginLeft: '25px'
      }
    }
  })
);

const buttonAlignment = makeStyles((theme: Theme) =>
  createStyles({
    backTo: {
      marginLeft: '0px',
      [theme.breakpoints.down(1025)]: {
        marginLeft: '5%'
      },
      [theme.breakpoints.between(0, 380)]: {
        marginLeft: '25px'
      }
    },
    sysInt: {
      [theme.breakpoints.up('lg')]: {
        marginLeft: '0px'
      },
      [theme.breakpoints.between(600, 1280)]: {
        marginLeft: '146px'
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px'
      },
      [theme.breakpoints.between(0, 380)]: {
        width: '120px',
        marginLeft: '25px'
      }
    },
    olrp: {
      marginLeft: '40px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '25px'
      },
      [theme.breakpoints.between(0, 380)]: {
        width: '120px'
      }
    }
  })
);

// used to refresh the width of subscribe buttons
function useWindowWidth() {
  const [width, setWidth] = useState([0]);
  useLayoutEffect(() => {
    function updateWidth() {
      setWidth([window.innerWidth]);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return width;
}

function OfferInfoHeader(props: OfferInfoProps) {
  const { t } = useTranslation();
  const [width] = useWindowWidth();
  const classes = ownStyles();
  const buttonAllignment = buttonAlignment();

  let history = useHistory();

  const ArbiraHasAnOffer = () => (
    <Box className={classes.offer}>
      {t('offerInfoHeader.arbiraHasAnOfferFor')}
    </Box>
  );

  function onOfferButtonClick(type: OfferInfoType) {
    if (type === OfferInfoType.SystemIntegrator)
      history.push(t('links.systemIntegratorsLink'));
    else history.push(t('links.olrpLink'));
  }

  function onBackButtonClick() {
    history.push(t('links.back'));
  }

  const Title = () => <Box className={classes.title}>{renderTitle()}</Box>;

  function renderTitle() {
    if (props.offerInfoType === OfferInfoType.SystemIntegrator)
      return t('offerInfoHeader.siTitle');
    else return t('offerInfoHeader.olrpTitle');
  }

  const backText = t('offerInfoHeader.backToLanding');
  const sysIntText = t('offerInfoHeader.systemIntegrators');
  const olrpText = t('offerInfoHeader.olrpVendors');

  function renderButtonsAccordingToWidth(
    widthAbove: Number,
    bckActive: boolean,
    sysIntActive: boolean,
    olrpActive: boolean
  ) {
    switch (widthAbove) {
      case 1280:
        return (
          <Grid container direction="row" justify="space-between">
            <Grid item xs={3} className={buttonAllignment.backTo}>
              <NoBckgrButton
                textValue={backText}
                active={bckActive}
                onclick={() => onBackButtonClick()}
              />
            </Grid>
            <Grid item container xs={6} justify="center">
              <Grid item className={buttonAllignment.sysInt}>
                <NoBckgrButton
                  textValue={sysIntText}
                  active={sysIntActive}
                  onclick={() =>
                    onOfferButtonClick(OfferInfoType.SystemIntegrator)
                  }
                />
              </Grid>
              <Grid item className={buttonAllignment.olrp}>
                <NoBckgrButton
                  textValue={olrpText}
                  active={olrpActive}
                  onclick={() => onOfferButtonClick(OfferInfoType.Olrp)}
                />
              </Grid>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        );
      case 960:
        return (
          <Grid container>
            <Box className={buttonAllignment.backTo}>
              <NoBckgrButton
                textValue={backText}
                active={bckActive}
                onclick={() => onBackButtonClick()}
              />
            </Box>
            <Box className={buttonAllignment.sysInt}>
              <NoBckgrButton
                textValue={sysIntText}
                active={sysIntActive}
                onclick={() =>
                  onOfferButtonClick(OfferInfoType.SystemIntegrator)
                }
              />
            </Box>
            <Box className={buttonAllignment.olrp}>
              <NoBckgrButton
                textValue={olrpText}
                active={olrpActive}
                onclick={() => onOfferButtonClick(OfferInfoType.Olrp)}
              />
            </Box>
          </Grid>
        );
      case 0:
        return (
          <Grid container direction="column">
            <Grid
              container
              item
              className={buttonAllignment.backTo}
              justify="flex-start"
            >
              <NoBckgrButton
                textValue={backText}
                active={bckActive}
                onclick={() => onBackButtonClick()}
              />
            </Grid>
            <Grid item container justify="center">
              <Grid item className={buttonAllignment.sysInt}>
                <NoBckgrButton
                  textValue={sysIntText}
                  active={sysIntActive}
                  onclick={() =>
                    onOfferButtonClick(OfferInfoType.SystemIntegrator)
                  }
                />
              </Grid>
              <Grid item className={buttonAllignment.olrp}>
                <NoBckgrButton
                  textValue={olrpText}
                  active={olrpActive}
                  onclick={() => onOfferButtonClick(OfferInfoType.Olrp)}
                />
              </Grid>
            </Grid>
          </Grid>
        );
    }
  }

  function renderButtons() {
    if (width > 1279) {
      if (props.offerInfoType === OfferInfoType.SystemIntegrator)
        return renderButtonsAccordingToWidth(1280, false, true, false);
      else return renderButtonsAccordingToWidth(1280, false, false, true);
    } else if (width > 959) {
      if (props.offerInfoType === OfferInfoType.SystemIntegrator)
        return renderButtonsAccordingToWidth(960, false, true, false);
      else return renderButtonsAccordingToWidth(960, false, false, true);
    } else {
      if (props.offerInfoType === OfferInfoType.SystemIntegrator)
        return renderButtonsAccordingToWidth(0, false, true, false);
      else return renderButtonsAccordingToWidth(0, false, false, true);
    }
  }

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.headerContent}
    >
      {renderButtons()}
      <div className={classes.line} />
      <Grid container direction="column" alignItems="baseline">
        <ArbiraHasAnOffer />
        <Title />
      </Grid>
    </Grid>
  );
}

export default OfferInfoHeader;
