import React, { useLayoutEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { WebAppAndMobileAppCardProps } from '../types/types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

const ownStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      [theme.breakpoints.up('md')]: {
        width: '1022px',
        margin: '20px 13px'
      },
      [theme.breakpoints.only('md')]: {
        width: '1022px',
        margin: '20px 13px',
        padding: '0px 0px 0px 30px'
      },
      [theme.breakpoints.down('sm')]: {
        margin: '60px 55px 0px 55px'
      },
      [theme.breakpoints.down('xs')]: {
        margin: '60px 20px 0px 20px'
      }
    },
    cardContent: {
      [theme.breakpoints.up('md')]: {
        maxWidth: '400px',
        margin: '60px 30px 60px 0px  '
      },
      [theme.breakpoints.only('md')]: {
        maxWidth: '300px',
        margin: '60px 30px 60px 0px  '
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: '400px',
        margin: '32px 20px 0px 20px'
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '300px',
        margin: '32px 20px 0px 20px'
      }
    },
    subtitle: {
      fontFamily: 'Lexend Tera',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '9px',
      lineHeight: '12px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '-0.05em',
      color: '#292336',
    },
    title: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.03em',
      color: '#E73636',
      [theme.breakpoints.up('xs')]: {
        fontSize: '24px',
        lineHeight: '36px',
        marginBottom: '20px'
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
        lineHeight: '30px',
        marginBottom: '11px'
      }
    },
    description: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '-0.02em',
      color: '#000000',

      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
        lineHeight: '24px'
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        lineHeight: '21px'
      }
    },
    cover: {
      border: '2px solid #FFFFFF',
      size: 'cover',
      boxShadow:
        '0px 2.91966px 2.91966px rgba(41, 35, 54, 0.0240518), 0px 7.38404px 7.38404px rgba(41, 35, 54, 0.0356659), 0px 15.0627px 15.0627px rgba(41, 35, 54, 0.0428115), 0px 31.0264px 31.0264px rgba(41, 35, 54, 0.0497761), 0px 85px 85px rgba(41, 35, 54, 0.07)',
      borderRadius: '5px',
      overflow: 'hidden',
      height: 'auto',
      [theme.breakpoints.down('xs')]: {
        width: '300px',
        height: '200px'
      }
    }
  })
);

// used to refresh the width of subscribe buttons
function useWindowWidth() {
  const [width, setWidth] = useState([0]);
  useLayoutEffect(() => {
    function updateWidth() {
      setWidth([window.innerWidth]);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return width;
}

function WebAppAndMobileAppCard(cardProps: WebAppAndMobileAppCardProps) {
  const { t } = useTranslation();
  const classes = ownStyles();
  const [width] = useWindowWidth();

  const descriptionItems: [] = t(
    'webAppAndMobileApp.' + cardProps.index + '.description',
    { returnObjects: true }
  );
  let descriptionIndex = 0;
  return (
    <Grid
      container
      direction="row"
      justify={width >= 960 ? 'space-between' : 'center'}
      alignItems="center"
      className={classes.card}
    >
      {(cardProps.index % 2 === 1 || width < 960) && (
        <img
          className={classes.cover}
          src={cardProps.img}
          alt={'cardProps.img'}
        />
      )}
      <Box className={classes.cardContent}>
        <Box className={classes.subtitle}>
          {t('webAppAndMobileApp.' + cardProps.index + '.subtitle')}
        </Box>
        <Typography className={classes.title}>
          {t('webAppAndMobileApp.' + cardProps.index + '.title')}
        </Typography>
        <div className={classes.description}>
          {descriptionItems.map(item => (
            <Box key={descriptionIndex++}>
              {item} <br /> <br />
            </Box>
          ))}
        </div>
      </Box>
      {cardProps.index % 2 === 0 && width >= 960 && (
        <img
          className={classes.cover}
          src={cardProps.img}
          alt={'cardProps.img'}
        />
      )}
    </Grid>
  );
}

export default WebAppAndMobileAppCard;
