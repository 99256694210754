import React, { useState, useLayoutEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import {
  OfferInfoProps,
  OfferInfoType,
  OfferInfoCardData,
  ImageCropped
} from '../types/types';
import { useTranslation } from 'react-i18next';
import OfferInfoCard from '../components/OfferInfoCard';
import OfferInfoImageButton from '../components/OfferInfoImageButton';
import arrowLeft from '../images/offerInfoImages/ButtonArrowLeft.png';
import arrowRight from '../images/offerInfoImages/ButtonArrowRight.png';
import arrowL from '../images/offerInfoImages/ArrowL.png';
import arrowML from '../images/offerInfoImages/ArrowMLeft.png';
import arrowMR from '../images/offerInfoImages/ArrowMRight.png';
import arrowSL from '../images/offerInfoImages/ArrowSLeft.png';
import arrowSR from '../images/offerInfoImages/ArrowSRight.png';
import gradient from '../images/offerInfoImages/Gradient.png';

const ownStyles = makeStyles((theme: Theme) =>
  createStyles({
    bodyContentSI: {
      margin: '40px 5% 0px 5%',
      [theme.breakpoints.between(1024, 1280)]: {
        margin: '40px 0px 0px 0px'
      },
      [theme.breakpoints.down('sm')]: {
        margin: '40px 5% 0px 5%'
      },
      [theme.breakpoints.down('xs')]: {
        margin: '28px 0px 0px 0px'
      }
    },
    bodyContentOlrp: {
      margin: '0px 5% 0px 5%',
      [theme.breakpoints.between(1024, 1280)]: {
        margin: '0px 0px 0px 0px'
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0px 5% 0px 5%'
      },
      [theme.breakpoints.down('xs')]: {
        margin: '0px 0px 0px 0px'
      }
    },
    imageStyle: {
      position: 'relative',
      objectFit: 'contain'
    },
    imageButtonStyle: {
      float: 'left'
    },
    imageButtonContainerStyle: {
      position: 'absolute',
      bottom: '15px',
      [theme.breakpoints.down(1024)]: {
        bottom: '-21px'
      }
    },
    arrowStyle: {
      position: 'absolute',
      top: '102px'
    },
    cardContainerStyle: {
      position: 'relative'
    },
    lineButtonBackgr: {
      position: 'absolute',
      width: 'calc(100% - 100px)',
      height: '0px',
      border: '2px solid #F3F2F3',
      left: '50px',
      bottom: '34px'
    },
    lineButtonBackgrActive: {
      position: 'absolute',
      left: '47px',
      height: '0px',
      border: '2px solid #E73636',
      bottom: '34px'
    },
    line: {
      width: '0px',
      height: '40px',
      border: '1px solid #7F7986',
      marginTop: '20px',
      marginLeft: 'calc(50% - 1px)'
    }
  })
);

// used to refresh the width of subscribe buttons
function useWindowWidth() {
  const [width, setWidth] = useState([0]);
  useLayoutEffect(() => {
    function updateWidth() {
      setWidth([window.innerWidth]);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return width;
}

function OfferInfoBody(props: OfferInfoProps) {
  const classes = ownStyles();
  const { t } = useTranslation();
  const [activeCardId, activateCardWithId] = useState(0);
  const [width] = useWindowWidth();

  const siCards: [] = t('sIBody', { returnObjects: true });
  const olrpCards: [] = t('olrpBody', { returnObjects: true });

  function loadCards() {
    let ret: OfferInfoCardData[] = [];

    if (props.offerInfoType === OfferInfoType.SystemIntegrator) {
      for (let i = 0; i < siCards.length; i++) {
        let Title: string = '';
        let Description: string = '';
        let Icon: string = '';
        Object.entries(siCards[i]).forEach(([key, value]) => {
          if (typeof value === 'string') {
            if (key === 'Title') Title = value.toString();
            if (key === 'Description') Description = value.toString();
            if (key === 'Icon') Icon = value.toString();
          }
        });

        let e: OfferInfoCardData = {
          title: Title,
          description: Description,
          icon: Icon,
          cardIndex: i
        };
        ret.push(e);
      }
    } else {
      for (let i = 0; i < olrpCards.length; i++) {
        let Title: string = '';
        let Description: string = '';
        let Icon: string = '';
        Object.entries(olrpCards[i]).forEach(([key, value]) => {
          if (typeof value === 'string') {
            if (key === 'Title') Title = value.toString();
            if (key === 'Description') Description = value.toString();
            if (key === 'Icon') Icon = value.toString();
          }
        });

        let e: OfferInfoCardData = {
          title: Title,
          description: Description,
          icon: Icon,
          cardIndex: i
        };
        ret.push(e);
      }
    }
    return ret;
  }

  const cardsOnPage: OfferInfoCardData[] = loadCards();

  function renderCard(card: OfferInfoCardData) {
    return (
      <OfferInfoCard
        key={card.cardIndex}
        disableRiple={props.offerInfoType === OfferInfoType.Olrp}
        card={card}
        active={
          card.cardIndex === activeCardId ||
          props.offerInfoType === OfferInfoType.Olrp
        }
        onclick={() => activateCardWithId(card.cardIndex)}
        cursorHower={props.offerInfoType === OfferInfoType.SystemIntegrator}
      />
    );
  }

  function renderGradientOverlayedCard(
    card: OfferInfoCardData,
    imageCrop: ImageCropped
  ) {
    return (
      <div
        style={{
          position: 'relative',
          display: 'inline-block',
          overflow: 'hidden',
          verticalAlign: 'top',
          cursor: 'pointer'
        }}
        onClick={() => activateCardWithId(card.cardIndex)}
      >
        <OfferInfoCard
          disableRiple={true}
          card={card}
          active={
            card.cardIndex === activeCardId ||
            props.offerInfoType === OfferInfoType.Olrp
          }
          cursorHower={props.offerInfoType === OfferInfoType.SystemIntegrator}
        />
        <div
          style={{
            position: 'absolute',
            background:
              imageCrop === ImageCropped.None
                ? ''
                : imageCrop === ImageCropped.CroppedLeft
                ? 'url(' + gradient + ')'
                : 'url(' + gradient + ')',
            transform:
              imageCrop === ImageCropped.CroppedRight ? 'rotate(180deg)' : '',
            width: '200px',
            height: '500px',
            top: '50px',
            opacity: '0.95',
            left: imageCrop === ImageCropped.CroppedLeft ? '0' : '',
            right: imageCrop === ImageCropped.CroppedRight ? '0' : ''
          }}
        />
      </div>
    );
  }

  function renderGradientOverlayedCardIcon(card: OfferInfoCardData) {
    return (
      <img
        src={require(`../images/offerInfoImages/${card.icon}`)}
        width={'100%'}
        height={'100%'}
        style={{ opacity: '0.5', cursor: 'pointer' }}
        alt={card.icon}
        onClick={() => activateCardWithId(card.cardIndex)}
      />
    );
  }

  function renderLArrow(indexBefore: number) {
    let currentPos = (100 / cardsOnPage.length) * (indexBefore + 1);
    if (width >= 1024)
      return indexBefore < cardsOnPage.length - 1 ? (
        <img
          key={indexBefore}
          className={classes.arrowStyle}
          src={arrowL}
          alt="arrowL"
          width="136px"
          style={{
            left:
              width >= 1280
                ? currentPos < 50
                  ? `calc(100% / ${cardsOnPage.length} * ${indexBefore +
                      1} - 98px)`
                  : currentPos > 50
                  ? `calc(100% / ${cardsOnPage.length} * ${indexBefore +
                      1} - 38px)`
                  : `calc(100% / ${cardsOnPage.length} * ${indexBefore +
                      1} - 68px)`
                : `calc(100% / ${cardsOnPage.length} * ${indexBefore +
                    1} - 68px)`
          }}
        />
      ) : (
        ''
      );
  }

  function renderSM_Arrows() {
    if (width >= 600 && width < 1024)
      return (
        <span>
          <img
            className={classes.arrowStyle}
            src={arrowML}
            alt="arrowML"
            width="68px"
            style={{
              visibility: activeCardId > 0 ? 'visible' : 'hidden',
              left: `calc(100% / 3 - 68px)`
            }}
          />
          <img
            className={classes.arrowStyle}
            src={arrowMR}
            alt="arrowMR"
            width="68px"
            style={{
              visibility:
                activeCardId < cardsOnPage.length - 1 ? 'visible' : 'hidden',
              left: `calc((100% / 3)*2)`
            }}
          />
        </span>
      );
    else if (width < 600)
      return (
        <span>
          <img
            className={classes.arrowStyle}
            src={arrowSL}
            alt="arrowSL"
            width="30px"
            style={{
              visibility: activeCardId > 0 ? 'visible' : 'hidden',
              left: `calc(100% / 3 - 30px)`
            }}
          />
          <img
            className={classes.arrowStyle}
            src={arrowSR}
            alt="arrowSR"
            width="30px"
            style={{
              visibility:
                activeCardId < cardsOnPage.length - 1 ? 'visible' : 'hidden',
              left: `calc((100% / 3)*2)`
            }}
          />
        </span>
      );
  }

  function renderSysIntBody() {
    return (
      <Grid container direction="column">
        {width >= 1024 ? (
          <Box className={classes.bodyContentSI} style={{ overflow: 'hidden' }}>
            <Grid
              container
              className={classes.imageStyle}
              direction="row"
              justify="space-between"
            >
              <img
                src={require(`../images/offerInfoImages/system-integrators-header-image-${activeCardId +
                  1}.png`)}
                width={'100%'}
                height={'100%'}
                alt={
                  'system-integrators-header-image-' +
                  (activeCardId + 1).toString()
                }
              />
              <div className={classes.lineButtonBackgr} />
              <div
                className={classes.lineButtonBackgrActive}
                style={{
                  width:
                    width < 1350 && width > 1280
                      ? `calc(70.5px + ${activeCardId} * ((100% - 100px) / ${cardsOnPage.length -
                          1} - 32px))`
                      : width <= 1280
                      ? `calc(100.5px + ${activeCardId} * ((100% - 100px) / ${cardsOnPage.length -
                          1} - 64px))`
                      : `calc(115px + ${activeCardId} * ((100% - 100px) / ${cardsOnPage.length -
                          1} - 64px))`
                }}
              />
              <Grid
                item
                container
                justify="space-between"
                className={classes.imageButtonContainerStyle}
              >
                {cardsOnPage.map(item => (
                  <Grid
                    key={item.cardIndex}
                    item
                    className={classes.imageButtonStyle}
                    style={{
                      marginLeft:
                        item.cardIndex === 0
                          ? width < 1350 && width > 1280
                            ? '70.5px'
                            : width <= 1280
                            ? '100.5px'
                            : '115px'
                          : '0px',
                      marginRight:
                        item.cardIndex === cardsOnPage.length - 1
                          ? width < 1350 && width > 1280
                            ? '70.5px'
                            : width <= 1280
                            ? '100.5px'
                            : '115px'
                          : '0px'
                    }}
                  >
                    <OfferInfoImageButton
                      buttonId={item.cardIndex}
                      active={item.cardIndex === activeCardId}
                      onclick={() => activateCardWithId(item.cardIndex)}
                      cropped={ImageCropped.None}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              className={classes.cardContainerStyle}
              style={
                width >= 1280
                  ? {}
                  : { paddingLeft: '30px', paddingRight: '30px' }
              }
            >
              {cardsOnPage.map(item => renderCard(item))}
              {cardsOnPage.map(item => renderLArrow(item.cardIndex))}
            </Grid>
          </Box>
        ) : (
          <Box className={classes.bodyContentSI} style={{ overflow: 'hidden' }}>
            <Grid container className={classes.imageStyle}>
              <img
                src={require(`../images/offerInfoImages/system-integrators-header-image-${activeCardId +
                  1}.png`)}
                width={'100%'}
                height={'100%'}
                alt={
                  'system-integrators-header-image-' +
                  (activeCardId + 1).toString()
                }
              />
              <Grid
                item
                container
                justify="space-between"
                className={classes.imageButtonContainerStyle}
                style={{ zIndex: 10 }}
              >
                {cardsOnPage.map(item =>
                  activeCardId > 0 && activeCardId < cardsOnPage.length - 1 ? (
                    item.cardIndex >= activeCardId - 1 &&
                    item.cardIndex <= activeCardId + 1 ? (
                      <Grid
                        key={item.cardIndex}
                        item
                        style={{
                          marginLeft:
                            width > 599
                              ? item.cardIndex < activeCardId
                                ? '32px'
                                : '0'
                              : '0',
                          marginRight:
                            width > 599
                              ? item.cardIndex > activeCardId
                                ? '32px'
                                : '0'
                              : '0'
                        }}
                      >
                        <OfferInfoImageButton
                          buttonId={item.cardIndex}
                          active={item.cardIndex === activeCardId}
                          onclick={() => activateCardWithId(item.cardIndex)}
                          cropped={
                            width > 599
                              ? ImageCropped.None
                              : item.cardIndex === activeCardId
                              ? ImageCropped.None
                              : item.cardIndex < activeCardId
                              ? ImageCropped.CroppedLeft
                              : ImageCropped.CroppedRight
                          }
                        />
                      </Grid>
                    ) : (
                      ''
                    )
                  ) : activeCardId === 0 ? (
                    item.cardIndex < 2 ? (
                      <Grid
                        key={item.cardIndex}
                        item
                        style={{
                          marginLeft:
                            width > 599
                              ? item.cardIndex === 0
                                ? 'calc(50% - 32px)'
                                : '0'
                              : item.cardIndex === 0
                              ? 'calc(50% - 32px)'
                              : '0',
                          marginRight:
                            width > 599
                              ? item.cardIndex > 0
                                ? '32px'
                                : '0'
                              : '0'
                        }}
                      >
                        <OfferInfoImageButton
                          buttonId={item.cardIndex}
                          active={item.cardIndex === activeCardId}
                          onclick={() => activateCardWithId(item.cardIndex)}
                          cropped={
                            width > 599
                              ? ImageCropped.None
                              : item.cardIndex === activeCardId
                              ? ImageCropped.None
                              : item.cardIndex < activeCardId
                              ? ImageCropped.CroppedLeft
                              : ImageCropped.CroppedRight
                          }
                        />
                      </Grid>
                    ) : (
                      ''
                    )
                  ) : item.cardIndex > cardsOnPage.length - 3 ? (
                    <Grid
                      key={item.cardIndex}
                      item
                      style={{
                        marginLeft:
                          width > 599
                            ? item.cardIndex === cardsOnPage.length - 1
                              ? '0'
                              : '32px'
                            : '0',
                        marginRight:
                          width > 599
                            ? item.cardIndex === cardsOnPage.length - 1
                              ? 'calc(50% - 32px)'
                              : '0'
                            : item.cardIndex === cardsOnPage.length - 1
                            ? 'calc(50% - 32px)'
                            : '0'
                      }}
                    >
                      <OfferInfoImageButton
                        buttonId={item.cardIndex}
                        active={item.cardIndex === activeCardId}
                        onclick={() => activateCardWithId(item.cardIndex)}
                        cropped={
                          width > 599
                            ? ImageCropped.None
                            : item.cardIndex === activeCardId
                            ? ImageCropped.None
                            : item.cardIndex < activeCardId
                            ? ImageCropped.CroppedLeft
                            : ImageCropped.CroppedRight
                        }
                      />
                    </Grid>
                  ) : (
                    ''
                  )
                )}
              </Grid>
            </Grid>
            <div style={{ textAlign: 'center' }}>
              <div className={classes.cardContainerStyle}>
                <div
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    height: '380px',
                    width: '100%'
                  }}
                >
                  {cardsOnPage.map(item =>
                    item.cardIndex === activeCardId ? (
                      <div
                        key={item.cardIndex}
                        style={{
                          position: 'absolute',
                          top: '0',
                          left: 'calc(50% - 137px)'
                        }}
                      >
                        {renderCard(item)}
                      </div>
                    ) : item.cardIndex === activeCardId - 1 ? (
                      width > 750 ? (
                        <div
                          key={item.cardIndex}
                          style={{
                            position: 'absolute',
                            top: '0',
                            left: '-90px'
                          }}
                        >
                          {renderGradientOverlayedCard(
                            item,
                            ImageCropped.CroppedLeft
                          )}
                        </div>
                      ) : (
                        <div
                          key={item.cardIndex}
                          style={{
                            position: 'absolute',
                            top: '60px',
                            left: width < 600 ? '-40px' : '18px'
                          }}
                        >
                          {renderGradientOverlayedCardIcon(item)}
                        </div>
                      )
                    ) : item.cardIndex === activeCardId + 1 ? (
                      width > 750 ? (
                        <div
                          key={item.cardIndex}
                          style={{
                            position: 'absolute',
                            top: '0',
                            right: '-90px'
                          }}
                        >
                          {renderGradientOverlayedCard(
                            item,
                            ImageCropped.CroppedRight
                          )}
                        </div>
                      ) : (
                        <div
                          key={item.cardIndex}
                          style={{
                            position: 'absolute',
                            top: '60px',
                            right: width < 600 ? '-40px' : '18px'
                          }}
                        >
                          {renderGradientOverlayedCardIcon(item)}
                        </div>
                      )
                    ) : (
                      ''
                    )
                  )}
                </div>
                {renderSM_Arrows()}
              </div>
              <div style={{ marginTop: '20px' }}>
                <img
                  src={arrowLeft}
                  alt="arrowLeft"
                  width="65px"
                  height="40px"
                  style={{ marginRight: '20px', cursor: 'pointer' }}
                  onClick={() =>
                    activateCardWithId(activeCardId > 0 ? activeCardId - 1 : 0)
                  }
                />
                <img
                  src={arrowRight}
                  alt="arrowRight"
                  width="65px"
                  height="40px"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    activateCardWithId(
                      activeCardId < cardsOnPage.length - 1
                        ? activeCardId + 1
                        : cardsOnPage.length - 1
                    )
                  }
                />
              </div>
              <div className={classes.line} />
            </div>
          </Box>
        )}
      </Grid>
    );
  }

  function renderOlrpBody() {
    return (
      <Grid container direction="column">
        {width >= 1024 ? (
          <Box className={classes.bodyContentOlrp}>
            <Grid
              container
              direction="row"
              justify="space-between"
              className={classes.cardContainerStyle}
              style={
                width > 1280
                  ? {}
                  : { paddingLeft: '30px', paddingRight: '30px' }
              }
            >
              {cardsOnPage.map(item => renderCard(item))}
              {cardsOnPage.map(item => renderLArrow(item.cardIndex))}
            </Grid>
          </Box>
        ) : (
          <Box className={classes.bodyContentOlrp}>
            <div style={{ textAlign: 'center' }}>
              <div className={classes.cardContainerStyle}>
                <div
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    height: '380px',
                    width: '100%'
                  }}
                >
                  {cardsOnPage.map(item =>
                    item.cardIndex === activeCardId ? (
                      <div
                        key={item.cardIndex}
                        style={{
                          position: 'absolute',
                          top: '0',
                          left: 'calc(50% - 137px)'
                        }}
                      >
                        {renderCard(item)}
                      </div>
                    ) : item.cardIndex === activeCardId - 1 ? (
                      width > 750 ? (
                        <div
                          key={item.cardIndex}
                          style={{
                            position: 'absolute',
                            top: '0',
                            left: '-90px'
                          }}
                        >
                          {renderGradientOverlayedCard(
                            item,
                            ImageCropped.CroppedLeft
                          )}
                        </div>
                      ) : (
                        <div
                          key={item.cardIndex}
                          style={{
                            position: 'absolute',
                            top: '60px',
                            left: width < 600 ? '-40px' : '18px'
                          }}
                        >
                          {renderGradientOverlayedCardIcon(item)}
                        </div>
                      )
                    ) : item.cardIndex === activeCardId + 1 ? (
                      width > 750 ? (
                        <div
                          key={item.cardIndex}
                          style={{
                            position: 'absolute',
                            top: '0',
                            right: '-90px'
                          }}
                        >
                          {renderGradientOverlayedCard(
                            item,
                            ImageCropped.CroppedRight
                          )}
                        </div>
                      ) : (
                        <div
                          key={item.cardIndex}
                          style={{
                            position: 'absolute',
                            top: '60px',
                            right: width < 600 ? '-40px' : '18px'
                          }}
                        >
                          {renderGradientOverlayedCardIcon(item)}
                        </div>
                      )
                    ) : (
                      ''
                    )
                  )}
                  {renderSM_Arrows()}
                </div>
              </div>
              <div style={{ marginTop: '20px' }}>
                <img
                  src={arrowLeft}
                  alt="arrowLeft"
                  width="65px"
                  height="40px"
                  style={{ marginRight: '20px', cursor: 'pointer' }}
                  onClick={() =>
                    activateCardWithId(activeCardId > 0 ? activeCardId - 1 : 0)
                  }
                />
                <img
                  src={arrowRight}
                  alt="arrowRight"
                  width="65px"
                  height="40px"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    activateCardWithId(
                      activeCardId < cardsOnPage.length - 1
                        ? activeCardId + 1
                        : cardsOnPage.length - 1
                    )
                  }
                />
              </div>
              <div className={classes.line} />
            </div>
          </Box>
        )}
      </Grid>
    );
  }

  function renderOfferinfoBody() {
    switch (props.offerInfoType) {
      case OfferInfoType.SystemIntegrator:
        return renderSysIntBody();
      case OfferInfoType.Olrp:
        return renderOlrpBody();
    }
  }

  return renderOfferinfoBody();
}

export default OfferInfoBody;
