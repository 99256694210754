import React, { useState, useLayoutEffect } from 'react';
import { OfferInfoProps, AlignTo, OfferInfoType } from '../types/types';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme, Grid, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { RedAndWhiteButton, OfferBottomButton } from '../components/Buttons';

const ownStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerContent: {
      marginTop: '50px',
      marginBottom: '50px',
      [theme.breakpoints.down(1024)]: {
        marginTop: '20px'
      }
    },
    line: {
      width: '120px',
      height: '0px',
      transform: 'rotate(90deg)',
      border: '1px solid #000000',
      [theme.breakpoints.down(1024)]: {
        width: '120px',
        display: 'none'
      }
    },
    contactEmail: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '36px',
      lineHeight: '120%',
      textAlign: 'center',
      letterSpacing: '0.03em',
      color: '#E73636',
      marginBottom: '20px',
      [theme.breakpoints.down(1024)]: {
        fontSize: '16px'
      }
    },
    contactVia: {
      fontFamily: 'Lexend Tera',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '13px',
      lineHeight: '13px',
      textAlign: 'center',
      color: '#000000',
      marginBottom: '10px',
      marginTop: '50px',
      letterSpacing: '-0.1em',
      textTransform: 'uppercase',
      [theme.breakpoints.down(1024)]: {
        fontSize: '11px',
        lineHeight: '11px',
        marginBottom: '5px',
      },
    },
    contact: {
      alignContent: 'center'
    }
  })
);

// used to refresh the width of subscribe buttons
function useWindowWidth() {
  const [width, setWidth] = useState([0]);
  useLayoutEffect(() => {
    function updateWidth() {
      setWidth([window.innerWidth]);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return width;
}

function OfferInfoFooter(props: OfferInfoProps) {
  const { t } = useTranslation();
  const [width] = useWindowWidth();
  const classes = ownStyles();
  let history = useHistory();

  function onBottomButtonClick(type: OfferInfoType) {
    renderBottomButtons();
    if (type === OfferInfoType.SystemIntegrator)
      history.push(t('links.systemIntegratorsLink'));
    else history.push(t('links.olrpLink'));
  }

  function onBackButtonClick() {
    history.push(t('links.back'));
  }

  function renderBottomButtons() {
    if (width < 1024) {
      return (
        <Grid container direction="row" justify="center" alignItems="center">
          {props.offerInfoType === OfferInfoType.SystemIntegrator ? (
            <OfferBottomButton
              textValue={t('offerBottomButtons.olrp')}
              headerText={t('offerBottomButtons.nextOffer')}
              active={true}
              alignTo={AlignTo.Center}
              onclick={() => onBottomButtonClick(OfferInfoType.Olrp)}
            />
          ) : (
            <OfferBottomButton
              textValue={t('offerBottomButtons.sysInt')}
              headerText={t('offerBottomButtons.arbiraOffer')}
              active={true}
              alignTo={AlignTo.Center}
              onclick={() =>
                onBottomButtonClick(OfferInfoType.SystemIntegrator)
              }
            />
          )}
        </Grid>
      );
    }
    if (props.offerInfoType === OfferInfoType.SystemIntegrator)
      return (
        <Grid container direction="row" justify="center" alignItems="center">
          <OfferBottomButton
            textValue={t('offerBottomButtons.sysInt')}
            headerText={t('offerBottomButtons.arbiraOffer')}
            active={false}
            alignTo={AlignTo.Right}
            onclick={() => onBottomButtonClick(OfferInfoType.SystemIntegrator)}
          />
          <div className={classes.line} />
          <OfferBottomButton
            textValue={t('offerBottomButtons.olrp')}
            headerText={t('offerBottomButtons.nextOffer')}
            active={true}
            alignTo={AlignTo.Left}
            onclick={() => onBottomButtonClick(OfferInfoType.Olrp)}
          />
        </Grid>
      );
    else
      return (
        <Grid container direction="row" justify="center" alignItems="center">
          <OfferBottomButton
            textValue={t('offerBottomButtons.sysInt')}
            headerText={t('offerBottomButtons.arbiraOffer')}
            active={true}
            alignTo={AlignTo.Right}
            onclick={() => onBottomButtonClick(OfferInfoType.SystemIntegrator)}
          />
          <div className={classes.line} />
          <OfferBottomButton
            textValue={t('offerBottomButtons.olrp')}
            headerText={t('offerBottomButtons.nextOffer')}
            active={false}
            alignTo={AlignTo.Left}
            onclick={() => onBottomButtonClick(OfferInfoType.Olrp)}
          />
        </Grid>
      );
  }

  function renderFooter() {
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.contact}
      >
        {/* Contact via */}
        <Box className={classes.contactVia}>{t('siteFooter.contactVia')}</Box>
        {/* Email address */}
        <Box className={classes.contactEmail}>
          {t('siteFooter.contactEmail')}
        </Box>
        <RedAndWhiteButton
          textValue={t('siteFooter.backToLandingPage')}
          width="227px"
          onclick={onBackButtonClick}
        />
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.footerContent}
    >
      {renderBottomButtons()}
      {renderFooter()}
    </Grid>
  );
}

export default OfferInfoFooter;
