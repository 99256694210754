import { MouseEventHandler } from 'react';

// ### Buttons
export interface ButtonProps {
  textValue: string;
  alignTo?: AlignTo;
  headerText?: string;
  width?: string;
  disabled?: boolean;
  active?: boolean;
  onclick?: MouseEventHandler;
}

export interface CardProps {
  index: number;
}

export interface WebAppAndMobileAppCardProps {
  index: number;
  img: string;
}

export interface HeroProps {
  setIsDemo: () => void;
}

export interface SubscribeProps {
  isDemo: boolean;
}

export interface OfferInfoProps {
  offerInfoType: OfferInfoType;
}

export enum OfferInfoType {
  SystemIntegrator,
  Olrp
}

export enum AlignTo {
  Left,
  Right,
  Center
}

export interface OfferInfoCardData {
  icon: string;
  title: string;
  description: string;
  cardIndex: number;
}

export interface OfferInfoCardProps {
  card: OfferInfoCardData;
  disableRiple: boolean;
  active: boolean;
  cursorHower: boolean;
  onclick?: MouseEventHandler;
}

export enum ImageCropped {
  CroppedLeft,
  CroppedRight,
  None
}

export interface OfferInfoImageButtonProps {
  buttonId: number;
  active: boolean;
  cropped: ImageCropped;
  onclick: MouseEventHandler;
}
