import React, { useLayoutEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { CardProps } from '../types/types';
import PlusSign from '../components/PlusSign';
import { useTranslation } from 'react-i18next';
import { RedAndWhiteButton } from './Buttons';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';

const ownStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      [theme.breakpoints.up('md')]: {
        width: '458px',
        background: '#F7F7F7',
        borderRadius: '1.7%',
        margin: '0px 11.5px 28px 11.5px',
        padding: '80px 73.5px 90px 73.5px',
        display: 'flex',
        flexDirection: 'column'
      },
      [theme.breakpoints.only('md')]: {
        // width: "max(426px, calc(50%-43.25‬px))",
        width: '370px',
        background: '#F7F7F7',
        borderRadius: '1.7%',
        margin: '0px 11.5px 28px 11.5px',
        padding: '20px 21px 55px 21px'
      },
      [theme.breakpoints.down('sm')]: {
        width: 'max(458px, calc(50%-43.25‬px))',
        // width: "300px",
        height: '416‬px',
        background: '#F7F7F7',
        borderRadius: '1.7%',
        margin: '0px 11.5px 28px 11.5px',
        padding: '20px 29px 30px 29px'
      },
      [theme.breakpoints.down('xs')]: {
        width: 'max(458px, calc(50%-43.25‬px))',
        // width: "300px",
        height: '416‬px',
        background: '#F7F7F7',
        borderRadius: '1.7%',
        margin: '0px 5px 28px 5px',
        padding: '20px 15px 30px 15px'
      }
    },
    cardContent: {
      [theme.breakpoints.up('md')]: {
        padding: '5px 5px 0px 35px  '
      },
      [theme.breakpoints.only('md')]: {},
      [theme.breakpoints.down('sm')]: {}
    },
    offerSubtitle: {
      fontFamily: 'Lexend Tera',
      fontStyle: 'normal',
      fontWeight: 'bold',
      color: '#000000',
      textTransform: 'uppercase',
      letterSpacing: '-0.1em',
      [theme.breakpoints.up('xs')]: {
        fontSize: '14px',
        marginBottom: '10px'
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '8px',
        marginBottom: '7px'
      }
    },
    offerTitle: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.03em',
      color: '#E73636',
      [theme.breakpoints.up('xs')]: {
        fontSize: '36px',
        lineHeight: '120%',
        marginBottom: '52px'
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
        lineHeight: '30px',
        marginBottom: '28px'
      }
    },
    offerDescription: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '-0.02em',
      color: '#000000',

      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
        lineHeight: '26px'
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        lineHeight: '21px'
      }
    }
  })
);

// used to refresh the width of subscribe buttons
function useWindowWidth() {
  const [width, setWidth] = useState([0]);

  useLayoutEffect(() => {
    function updateWidth() {
      setWidth([window.innerWidth]);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return width;
}

function OfferCard(cardProps: CardProps) {
  const { t } = useTranslation();
  const classes = ownStyles();
  let history = useHistory();

  function onMoreInfoClick(link: string) {
    history.push(t(link));
  }

  const descriptionItems: [] = t(
    'offerForSection.' + cardProps.index + '.description',
    { returnObjects: true }
  );
  let descriptionIndex = 0;
  const [width] = useWindowWidth();
  return (
    <Card className={classes.card}>
      <PlusSign />
      <CardContent className={classes.cardContent}>
        <Typography className={classes.offerSubtitle}>
          {t('offerForSection.' + cardProps.index + '.subtitle')}
        </Typography>
        <Typography className={classes.offerTitle}>
          {t('offerForSection.' + cardProps.index + '.title')}
        </Typography>
        <div className={classes.offerDescription}>
          {descriptionItems.map(item => {
            if (item !== '')
              return (
                <Box key={descriptionIndex++}>
                  {item} <br /> <br />
                </Box>
              );
            else return '';
          })}
        </div>
      </CardContent>
      <CardActions style={{ marginTop: 'auto' }}>
        <Grid container direction="column" justify="center" alignItems="center">
          <RedAndWhiteButton
            textValue={t('offerForSection.' + cardProps.index + '.button')}
            width={width > 959 ? '200px' : '250px'}
            onclick={() =>
              onMoreInfoClick(
                t('offerForSection.' + cardProps.index + '.linkDoNotTranslate')
              )
            }
          />
        </Grid>
      </CardActions>
    </Card>
  );
}

export default OfferCard;
